<template lang="en">
  <div id="app">
    <div class="background">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'App',
  setup() {
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;  // Use the environment variable
  },
  created() {
    const savedTheme = localStorage.getItem('theme') || 'light';
    this.applyTheme(savedTheme);
  },
  methods: {
    applyTheme(theme) {
      const themeFile = theme === 'dark' ? '/styles/darktheme.css' : '/styles/lighttheme.css';
      let linkElement = document.getElementById('theme-link');
      if (!linkElement) {
        linkElement = document.createElement('link');
        linkElement.id = 'theme-link';
        linkElement.rel = 'stylesheet';
        document.head.appendChild(linkElement);
      }
      linkElement.href = themeFile;

      this.updateMetaTags(theme);
      localStorage.setItem('theme', theme);
    },
    updateMetaTags(theme) {
      const themeColor = theme === 'dark' ? '#03010a' : '#faf7fc';
      document.querySelector('meta[name="theme-color"]').setAttribute('content', themeColor);
      const statusBarStyle = theme === 'dark' ? 'black-translucent' : 'default';
      document.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]').setAttribute('content', statusBarStyle);
    }
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rouge+Script&display=swap');

#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--text-color);
  position: relative;
  overflow: hidden;
}

.background {
  background: var(--background-gradient);
  background-attachment: fixed;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
</style>

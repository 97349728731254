import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';

// Use environment variable for baseURL
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,  // Use the environment variable
  withCredentials: true,
});

const app = createApp(App);

// Provide axios to all components
app.provide('axios', axiosInstance);
app.config.globalProperties.$http = axiosInstance;

app.use(router).mount('#app');

// Add request interceptor
axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('access_token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

// Add response interceptor
axiosInstance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response && error.response.status === 401) {
    router.push('/login');
  }
  return Promise.reject(error);
});

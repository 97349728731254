<template lang="en">
  <div v-if="isOpen" class="upload-modal">
    <div class="modal-content">
      <form @submit.prevent="uploadFile">
        <div class="file-upload">
          <input type="file" id="file-input" @change="handleFileChange" accept="image/*" hidden />
          <label for="file-input" class="file-upload-label">
            <div class="file-upload-icon">
              <img src="@/assets/upload-icon.png" alt="Upload Icon" />
            </div>
            <!-- Display image preview -->
            <div v-if="filePreview" class="media-preview">
              <img v-if="isImage" :src="filePreview" alt="Image Preview" />
            </div>
            <p>{{ truncatedFileName || 'Choose an image' }}</p>
            <small>Only image formats: jpg, jpeg, png, webp</small>
          </label>
        </div>
        <button type="submit">Upload</button>
        <button type="button" @click="cancelUpload" class="cancel-button">Cancel</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    isOpen: Boolean,
    uploadUrl: {
      type: String,
      required: true,
    },
    successMessage: {
      type: String,
      default: 'Image uploaded successfully',
    },
  },
  data() {
    return {
      file: null,
      fileName: '', // To store the file name for display
      filePreview: null, // To store the image preview URL
      isImage: false, // To determine if the selected file is an image
    };
  },
  computed: {
    truncatedFileName() {
      if (this.fileName.length > 20) {
        return this.fileName.slice(0, 20) + '...';
      }
      return this.fileName;
    },
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.file = file;
        this.fileName = file.name;

        // Restrict file types to images
        const fileExtension = this.fileName.split('.').pop().toLowerCase();
        this.isImage = ['jpg', 'jpeg', 'png', 'webp'].includes(fileExtension);

        if (!this.isImage) {
          alert('Please upload a valid image file (jpg, jpeg, png, webp)');
          this.clearFileInput();
          return;
        }

        // Create a preview URL for the image
        const reader = new FileReader();
        reader.onload = (e) => {
          this.filePreview = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    async uploadFile() {
      if (!this.file) {
        alert('Please select an image to upload.');
        return;
      }

      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('filename', this.file.name);

      const accessToken = localStorage.getItem('access_token');

      try {
        const response = await axios.post(this.uploadUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${accessToken}`,
          },
        });
        console.log(response.data);
        this.clearFileInput();
        this.$emit('upload-success'); // Emit the success event without showing the alert here
      } catch (error) {
        console.error('Error uploading image:', error);
        alert('Failed to upload image');
      }
    },
    cancelUpload() {
      this.clearFileInput();
      this.$emit('close');
    },
    clearFileInput() {
      this.file = null;
      this.fileName = '';
      this.filePreview = null;
      this.isImage = false;

      const fileInput = document.getElementById('file-input');
      if (fileInput) {
        fileInput.value = '';
      }
    },
  },
};
</script>

<style scoped>
.upload-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 300px;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9000;
}

.modal-content {
  min-width: 300px;
  background: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  max-width: 300px;
}

form {
  display: flex;
  flex-direction: column;
}

.file-upload {
  margin-bottom: 10px;
  border: 2px dashed rgba(131, 95, 139, 1);
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}

.file-upload-label {
  display: block;
  cursor: pointer;
  max-width: 300px;
}

.file-upload-icon img {
  width: 50px;
}

.media-preview img {
  max-width: 100%;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-top: 15px;
  max-height: 250px;
}

.file-upload-label p {
  color: #000000;
  font-size: 12px;
}

.file-upload small {
  display: block;
  font-size: 12px;
  color: #666;
}

button {
  padding: 10px;
  margin-top: 10px;
  border: none;
  background: rgba(142, 100, 149, 1);
  color: black;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-button {
  padding: 10px;
  margin-top: 10px;
  border: none;
  background: lightgray;
  color: black;
  border-radius: 5px;
  cursor: pointer;
}
</style>

<template>
  <BaseError
      :errorCode="404"
      errorMessage="Oops! The page you're looking for doesn't exist."
  />
</template>

<script>
import BaseError from './BaseError.vue';

export default {
  name: 'NotFoundError',
  components: { BaseError }
}
</script>
<template>
  <div v-if="isOpen" class="upload-modal">
    <div class="modal-content">
      <form @submit.prevent="uploadFiles">
                <textarea
                    v-model="description"
                    placeholder="Enter description"
                    @keydown.space.stop.prevent="allowSpace"
                ></textarea>

        <!-- Single div for all file uploads -->
        <div class="file-upload" >
          <input type="file" id="file-input" @change="handleFileChange" multiple hidden />
          <label for="file-input" class="file-upload-label" v-if="files.length < 4">
            <div class="file-upload-icon">
              <img src="@/assets/upload-icon.png" alt="Upload Icon" />
            </div>
            <p>Select files</p>
            <small>Up to 300MB. You can upload up to 4 files.</small>
          </label>
        </div>
          <!-- Preview all the selected files side by side with 'X' to remove -->
          <div class="media-previews">
            <div v-for="(file, index) in files" :key="index" class="media-preview">
              <img v-if="isImage(file)" :src="file.preview" alt="File Preview" />
              <video v-if="isVideo(file)" :src="file.preview" controls width="100%" />
              <audio v-if="isAudio(file)" :src="file.preview" controls />
              <!-- X button to remove a file -->
              <button type="button" class="remove-btn" @click="removeFile(index)">✕</button>

          </div>
        </div>

        <button type="submit" :disabled="files.length === 0">Upload</button>
        <button type="button" @click="cancelUpload" class="cancel-button">Cancel</button>
      </form>
    </div>
  </div>
</template>


<script>
import axios from 'axios';

export default {
  props: {
    isOpen: Boolean,
  },
  data() {
    return {
      description: '',
      files: [], // Initialize an empty array for files
    };
  },
  methods: {
    handleFileChange(event) {
      const selectedFiles = Array.from(event.target.files);

      // Prevent adding more than 4 files
      if (this.files.length + selectedFiles.length > 4) {
        alert('You can only upload up to 4 files.');
        return;
      }

      selectedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          // Add each new file to the existing array of files
          this.files.push({
            file: file,
            name: file.name,
            preview: e.target.result,
          });
        };
        reader.readAsDataURL(file);
      });
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    async uploadFiles() {
      const formData = new FormData();
      formData.append('description', this.description);

      this.files.forEach((fileObj, index) => {
        if (fileObj.file) {
          formData.append(`files`, fileObj.file);
          formData.append(`filenames[${index}]`, fileObj.file.name);
          formData.append(`fileSizes[${index}]`, fileObj.file.size);
        }
      });

      const accessToken = localStorage.getItem('access_token');

      try {
        const response = await axios.post('/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${accessToken}`,
          },
        });
        console.log(response.data);
        this.clearFileInputs();
        this.$emit('upload-success');
      } catch (error) {
        console.error('Error uploading files:', error);
      }
    },
    cancelUpload() {
      this.clearFileInputs();
      this.$emit('close');
    },
    clearFileInputs() {
      this.description = '';
      this.files = [];
    },
    isImage(fileObj) {
      return fileObj.file && fileObj.file.type.startsWith('image/');
    },
    isVideo(fileObj) {
      return fileObj.file && fileObj.file.type.startsWith('video/');
    },
    isAudio(fileObj) {
      return fileObj.file && fileObj.file.type.startsWith('audio/');
    },
    allowSpace(event) {
      event.preventDefault();
      this.description += ' ';
    },
  },
};
</script>

<style scoped>
.upload-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 300px;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9000;
}

.modal-content {
  min-width: 300px;
  background: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  max-width: 300px;
}

form {
  display: flex;
  flex-direction: column;

}

textarea {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid rgba(131, 95, 139, 1);
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

.file-upload {
  margin-bottom: 10px;
  border: 2px dashed rgba(131, 95, 139, 1);
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  min-height: 120px;
}

.media-preview {
  max-width: 62px;
  max-height: 62px;
  margin: 5px;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  position: relative; /* So the X button stays inside the preview */
}

.media-preview img,
.media-preview video {
  max-width: 70%;
  max-height: 70%;
  object-fit: cover;
}
.file-upload-label p{
  color: #000000;
  font-size: 12px;
}

.file-upload small {
  display: block; /* Ensures it's on its own line */
  font-size: 12px; /* Adjust the size if needed */
  color: #666; /* You can change the color to make it more visible */
}
.remove-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  padding: 2px 5px;
  border-radius: 50%;
}

.file-upload-label {
  display: block;
  cursor: pointer;
  max-width: 300px;
}

.file-upload-icon img {
  width: 50px;
}

.media-previews {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.media-preview {
  width: 100px;
  height: 100px;
  margin: 5px;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
}

.media-preview img,
.media-preview video {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

button {
  padding: 10px;
  margin-top: 10px;
  border: none;
  background: rgba(142, 100, 149, 1);
  color: black;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-button {
  padding: 10px;
  margin-top: 10px;
  border: none;
  background: lightgray;
  color: black;
  border-radius: 5px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .modal-content {
    min-width: 300px;
    background: white;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    max-width: 100%;
  }
}
</style>

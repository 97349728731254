<template lang="en">
  <div v-if="comment" class="comment">
    <img :src="getProfileImage(comment.profileImage)" alt="Profile" class="profile-image" />
    <div class="comment-details">
      <div class="comment-header">
        <span class="username">{{ comment.username }}</span>
        <span class="created-at">{{ formatDate(comment.created_at || comment.createdAt) }}</span>
      </div>
      <p class="content">{{ comment.content }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    comment: Object
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return 'Date unknown';
      try {
        // Remove the nanosecond part if present
        const cleanDateString = dateString.split('.')[0];
        const date = new Date(cleanDateString);
        if (isNaN(date.getTime())) {
          return 'Invalid date';
        }
        const options = {year: 'numeric', month: 'long', day: 'numeric'};
        return date.toLocaleString(undefined, options);
      } catch (error) {
        console.error('Error formatting date:', error);
        return 'Date format error';
      }
    },
    getProfileImage(profileImage) {
      const accessToken = localStorage.getItem('access_token'); // Get the token from local storage
      if (!profileImage) {
        return require('@/assets/images/360_F_549983970_bRCkYfk0P6PP5fKbMhZMIb07mCJ6esXL.jpg');
      }

      // Add token to image URL to ensure authorization
      return `${axios.defaults.baseURL}/uploads/profile_images/${profileImage}?token=${accessToken}`;
    }
  }
};
</script>

<style scoped>
.comment {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  margin-top: 10px;
  border-bottom: 1px solid rgba(180, 139, 187, 0.4);
}

.profile-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  border: 1px solid #ddd;
  object-fit: cover;
}

.comment-details {
  flex: 1;
}

.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.username {
  top: 5px;
  color: var(--creator-name-color, white);
  font-family: 'Rouge Script', cursive;
  font-weight: normal;
}

.created-at {
  font-size: 0.8em;
  color: #888;
}

.content {
  color: grey;
  font-size: 14px;
  margin-top: 5px;
}

@media screen and (max-width: 768px) {
  /* Responsive styling if needed */
}
</style>

<template>
  <div class="modal-backdrop" @click="$emit('close')">
    <div
        class="modal"
        @click.stop
        role="dialog"
        aria-modal="true"
        aria-labelledby="tribute-modal-title"
    >
      <h2 id="tribute-modal-title">Tribute</h2>
      <div class="creator-info">
        <img
            :src="creatorProfileImage"
            alt="Creator Profile"
            class="profile-image"
        />
        <span class="creator-name">{{ creatorName }}</span>
      </div>
      <div class="tribute-input">
        <label for="amount">Tribute Amount</label>
        <input
            ref="amountInput"
            id="amount"
            v-model="amount"
            type="number"
            min="5"
            step="1"
            placeholder="$ Tribute"
        />
      </div>
      <p class="minimum-text">Minimum $5 USD</p>
      <div class="name-input">
        <input v-model="cardholderName" type="text" placeholder="Cardholder Name" />
      </div>
      <div class="email-input">
        <input
            v-model="cardholderEmail"
            type="email"
            placeholder="Email (for receipt)"
        />
      </div>
      <div class="card-element-container">
        <div id="card-element"></div>
        <div id="card-errors" role="alert">{{ cardError }}</div>
      </div>
      <textarea v-model="comment" placeholder="Add a comment (optional)"></textarea>
      <div class="terms-checkbox">
        <input type="checkbox" v-model="agreedToTerms" id="agree" />
        <label for="agree">
          I agree to the
          <a href="#" @click.prevent="openTermsModal">terms and conditions</a>
        </label>
      </div>
      <div class="modal-buttons">
        <button @click="$emit('close')" class="cancel-button">Cancel</button>
        <button
            @click="confirmTribute"
            class="send-button"
            :disabled="isProcessing || !amount || !cardholderName || !agreedToTerms"
        >
          {{ isProcessing ? 'Processing...' : 'Send Tribute' }}
        </button>
      </div>
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
      <div v-if="successMessage" class="success-message">{{ successMessage }}</div>

      <!-- Terms and Conditions Modal -->
      <TermsAndConditions v-if="showTermsModal" @close="closeTermsModal" />
    </div>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import TermsAndConditions from './TermsAndConditions.vue';

export default {
  components: {
    TermsAndConditions,
  },
  props: {
    creatorName: {
      type: String,
      required: true,
    },
    creatorProfileImage: {
      type: String,
      required: true,
    },
    creatorId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      amount: null,
      comment: '',
      cardholderName: '',
      cardholderEmail: '',
      agreedToTerms: false,
      stripe: null,
      cardElement: null,
      cardError: '',
      errorMessage: '',
      successMessage: '',
      isProcessing: false,
      showTermsModal: false,
    };
  },
  methods: {
    async confirmTribute() {
      this.errorMessage = '';
      this.successMessage = '';

      if (!this.amount || this.amount < 5) {
        this.errorMessage = 'Please enter a valid amount (minimum $5).';
        return;
      }
      if (!this.cardholderName.trim()) {
        this.errorMessage = 'Please enter the cardholder name.';
        return;
      }
      if (!this.cardholderEmail.trim()) {
        this.errorMessage = 'Please enter your email address.';
        return;
      } else if (!this.validateEmail(this.cardholderEmail)) {
        this.errorMessage = 'Please enter a valid email address.';
        return;
      }
      if (!this.agreedToTerms) {
        this.errorMessage = 'You must agree to the terms and conditions.';
        return;
      }

      this.isProcessing = true;
      try {
        // Create PaymentIntent on the server
        const response = await axios.post('/payments/create-payment-intent', {
          amount: this.amount * 100, // Amount in cents
          currency: 'usd',
          creatorId: this.creatorId
        }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
        });
        const { clientSecret } = response.data;

        // Confirm the payment with Stripe.js
        const { paymentIntent, error } = await this.stripe.confirmCardPayment(
            clientSecret,
            {
              payment_method: {
                card: this.cardElement,
                billing_details: {
                  name: this.cardholderName,
                  email: this.cardholderEmail,
                },
              },
            }
        );

        if (error) {
          this.errorMessage = error.message;
          this.isProcessing = false;
        } else if (paymentIntent.status === 'succeeded') {
          this.successMessage = 'Payment successful! Thank you for your tribute.';
          setTimeout(() => {
            this.$emit('payment-success', {
              amount: this.amount,
              comment: this.comment,
              paymentIntentId: paymentIntent.id,
            });
            this.$emit('close');
          }, 2000);
        }
      } catch (error) {
        console.error('Payment error:', error.response?.data?.message || error.message);
        this.errorMessage = 'An error occurred during payment processing. Please try again.';
        this.isProcessing = false;
      }
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    openTermsModal() {
      this.showTermsModal = true;
    },
    closeTermsModal() {
      this.showTermsModal = false;
    },
  },
  async mounted() {
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
    const elements = this.stripe.elements();
    this.cardElement = elements.create('card', {
      hidePostalCode: true // This disables the postal code input
    });

    this.cardElement.mount('#card-element');

    this.cardElement.on('change', (event) => {
      if (event.error) {
        this.cardError = event.error.message;
      } else {
        this.cardError = '';
      }
    });

    // Set focus to the amount input
    this.$nextTick(() => {
      this.$refs.amountInput.focus();
    });
  },
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  min-width: 300px;
  background: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  max-width: 300px;
  overflow: hidden;
}

.modal h2 {
  margin-top: 0;
  color: #3d2946;
  text-align: left;
  font-size: 1.3rem;
  font-family: 'Roboto', sans-serif;
  font-weight: lighter;
}

.creator-info {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.creator-name {
  font-weight: lighter;
  font-family: 'Rouge Script', cursive;
  font-size: 1.6rem;
  color: #3d2946;
}

.tribute-input, .email-input {
  max-width: 350px;
  font-size: 16px;
}

.tribute-input input, .email-input input, .name-input input {
  width: 100%;
  margin-bottom: 10px;
  min-height: 40px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid rgba(131, 95, 139, 1);
  background: rgba(0, 0, 0, 0);
  color: #3d2946;
  max-width: 350px;
  box-sizing: border-box;
}

.minimum-text {
  font-size: 12px;
  color: #3d2946;
  margin-bottom: 15px;
  text-align: left;
  margin-left: 10px;
}

.card-element-container {
  margin-bottom: 15px;
}

#card-element {
  border: 1px solid rgba(131, 95, 139, 1);
  border-radius: 5px;
  padding: 10px;
  background: rgba(0, 0, 0, 0);
  color: #3d2946;
}

#card-errors {
  color: red;
  margin-top: 5px;
}

textarea {
  width: 100%;
  margin-bottom: 15px;
  resize: none;
  min-height: 60px;
  border-radius: 5px;
  border: 1px solid rgba(131, 95, 139, 1);
  font-family: 'Roboto', sans-serif;
  background: rgba(0, 0, 0, 0);
  color: #3d2946;
  box-sizing: border-box;
  font-size: 16px;
}

.terms-checkbox {
  margin-bottom: 15px;
  font-size: 14px;
  color: #3d2946;
}

.terms-checkbox input {
  margin-right: 5px;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
}

.modal-buttons button {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 50px;
}

.cancel-button {
  background-color: lightgrey;
  color: #333;
}

.send-button {
  background-color: #613d69ff;
  color: white;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.success-message {
  color: green;
  margin-top: 10px;
}

::placeholder {
  color: #3d2946;
}

.terms-content h1 {
  text-align: center;
  color: #3d2946;
}

.terms-text {
  color: #3d2946;
  font-size: 16px;
  line-height: 1.5;
}

.terms-text p {
  margin-bottom: 15px;
}
</style>
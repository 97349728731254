<template>
  <div class="modal-overlay" @click.self="$emit('close')">
    <div class="modal-card">
      <div class="creator-banner" :style="{ backgroundImage: `url(${creatorBanner})` }">
        <div class="creator-profile">
          <img :src="creatorProfileImage" alt="Creator Profile" class="profile-image">
        </div>
      </div>
      <div class="modal-content">
        <h2>{{ isSubscribed ? `Unsubscribe from ${creatorUsername}` : `Subscribe to ${creatorUsername}` }}</h2>
        <p class="price">{{ subscriptionPrice }} per month</p>

        <div v-if="!isSubscribed" class="payment-info">
          <input type="text" placeholder="Card Number" class="card-input">
          <div class="card-details">
            <input type="text" placeholder="MM/YY" class="card-input small">
            <input type="text" placeholder="CVC" class="card-input small">
          </div>
        </div>

        <div class="button-group">
          <button @click="$emit('close')" class="btn cancel">Cancel</button>
          <button @click="handleAction" class="btn" :class="{ 'subscribe': !isSubscribed, 'unsubscribe': isSubscribed }">
            {{ isSubscribed ? 'Unsubscribe' : 'Subscribe' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import eventBus from '@/utils/eventBus';

export default {
  name: 'SubscriptionModal',
  props: {
    creatorUsername: {
      type: String,
      required: true
    },
    creatorBanner: {
      type: String,
      required: true
    },
    creatorProfileImage: {
      type: String,
      required: true
    },
    subscriptionPrice: {
      type: String,
      required: true
    },
    isSubscribed: {
      type: Boolean,
      required: true
    },
    creatorId: {
      type: String,
      required: true
    }
  },
  methods: {
    async handleAction() {
      if (this.isSubscribed) {
        await this.$emit('unsubscribe');
      } else {
        await this.subscribe();
      }
    },
    async subscribe() {
      try {
        // Subscribe to the creator
        await this.$emit('subscribe');

        // Create a new conversation
        const conversationResponse = await axios.post('/conversations', {
          user1_id: localStorage.getItem('user_id'),
          user2_id: this.creatorId
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        });

        console.log('Conversation created:', conversationResponse.data);

        // Send the initial message
        if (conversationResponse.data && conversationResponse.data.conversation_id) {
          await this.sendMessage(conversationResponse.data.conversation_id);
        } else {
          console.error('Conversation ID not found in the response');
        }

        this.$emit('close');
      } catch (error) {
        console.error('Error during subscription process:', error);
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
        }
      }
    },
    async sendMessage(conversationId) {
      try {
        const messageData = {
          conversation_id: conversationId,
          content: "An honor subscribing to You"
        };
        console.log('Sending message data:', messageData);
        const messageResponse = await axios.post('/messages', messageData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        });
        console.log('Message sent, response:', messageResponse.data);
        eventBus.emit('message-sent');
      } catch (error) {
        console.error('Error sending message:', error);
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
        }
      }
    }
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-card {
  background-color: white;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.creator-banner {
  height: 100px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.creator-profile {
  position: absolute;
  bottom: -30px;
  left: 20px;
}

.profile-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid white;
}

.modal-content {
  padding: 40px 20px 20px;
}

h2 {
  margin-top: 0;
  color: #333;
}

.price {
  font-weight: bold;
  color: #8e6495;
}

.payment-info {
  margin-top: 20px;
}

.card-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.card-details {
  display: flex;
  justify-content: space-between;
}

.card-input.small {
  width: 48%;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.btn.cancel {
  background-color: #ccc;
  color: #333;
}

.btn.subscribe {
  background-color: #8e6495;
  color: white;
}

.btn.unsubscribe {
  background-color: #d9534f;
  color: white;
}
</style>
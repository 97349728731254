<template>
  <BaseError
      :errorCode="503"
      errorMessage="Service Unavailable: The server is temporarily unable to handle your request. Please try again later."
  />
</template>

<script>
import BaseError from './BaseError.vue';

export default {
  name: 'ServiceUnavailableError',
  components: { BaseError }
}
</script>
<template>
  <div class="audio-player">

    <audio
        ref="audioElement"
        @timeupdate="updateProgress"
        @loadedmetadata="handleMetadataLoaded"
        @progress="updateBuffer"
        @canplay="audioLoaded"
        @error="handleAudioError"
        preload="metadata"
    >
      <source :src="getMediaUrl(audioSrc)" type="audio/webm" />
      <source :src="getMediaUrl(audioSrc)" type="audio/mp4" />
      <source :src="getMediaUrl(audioSrc)" type="audio/mpeg" />
      <source :src="getMediaUrl(audioSrcOgg)" type="audio/ogg" />
      Your browser does not support the audio element.
    </audio>

    <!-- Controls Container -->
    <div class="controls">
      <!-- Top Section with Played Time and Play/Pause Button -->
      <div class="top-controls">
        <!-- Played Time -->
        <div class="played">
          <img :src="DSText" alt="DS Text" />
          <p>Audio</p>
          <span>{{ currentTime }} / {{ totalDuration }}</span>
        </div>

        <!-- Play/Pause Button -->
        <button
            class="play-pause-btn"
            @click="togglePlayPause"
            @touchstart.prevent="togglePlayPause"
        >
          <img
              :src="isPlaying ? pauseIcon : playIcon"
              alt="Play/Pause Icon"
              class="play-pause-icon"
          />
        </button>
      </div>

      <!-- Progress Bar -->
      <div
          class="progress-bar"
          @click="setProgress"
          @touchstart.prevent="handleTouchStart"
          @touchmove.prevent="handleTouchMove"
          @touchend.prevent="handleTouchEnd"
      >
        <div class="progress" :style="{ width: progress + '%' }"></div>
        <div class="buffered" :style="{ width: bufferedProgress + '%' }"></div>
      </div>
    </div>

    <div v-if="loadingError" class="loading-error">
      <p>{{ loadingError }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    audioSrc: {
      type: String,
      required: true,
    },
    audioSrcOgg: {
      type: String,
      default: "",
    },
    audioTitle: {
      type: String,
      default: "Audio",
    },
  },
  data() {
    return {
      isPlaying: false,
      progress: 0,
      bufferedProgress: 0,
      currentTime: "00:00",
      totalDuration: "00:00",
      loadingError: null,
      playIcon: require('@/assets/images/playicon.webp'),
      pauseIcon: require('@/assets/images/pauseicon.webp'),
      DSText: require('@/assets/images/DSText.png'),
      audioDuration: 0,
      isSeeking: false,
      baseURL: process.env.VUE_APP_API_URL,
    };
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyboardControls);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeyboardControls);
  },
  methods: {
    togglePlayPause() {
      const audio = this.$refs.audioElement;
      if (!audio) {
        console.error("Audio element not found");
        return;
      }
      if (this.isPlaying) {
        audio.pause();
      } else {
        this.playAudio();
      }
      this.isPlaying = !this.isPlaying;
    },
    playAudio() {
      const audio = this.$refs.audioElement;
      audio.play().then(() => {
        this.loadingError = null;
      }).catch(error => {
        console.error("Playback failed:", error);
        this.loadingError = "Playback failed. Please try again.";
        this.isPlaying = false;
      });
    },
    updateProgress() {
      const audio = this.$refs.audioElement;
      if (!audio || isNaN(audio.duration) || this.isSeeking) {
        return;
      }
      this.progress = (audio.currentTime / audio.duration) * 100;
      this.currentTime = this.formatTime(audio.currentTime);

      if (this.totalDuration === "00:00" && !isNaN(audio.duration)) {
        this.totalDuration = this.formatTime(audio.duration);
      }
    },
    updateBuffer() {
      const audio = this.$refs.audioElement;
      if (audio.buffered.length > 0) {
        this.bufferedProgress = (audio.buffered.end(audio.buffered.length - 1) / audio.duration) * 100;
      }
    },
    handleMetadataLoaded() {
      const audio = this.$refs.audioElement;
      if (audio && !isNaN(audio.duration)) {
        this.audioDuration = audio.duration;
        this.totalDuration = this.formatTime(this.audioDuration);
      } else {
        this.totalDuration = "Loading...";
        setTimeout(() => this.tryGetDuration(), 1000);
      }
      console.log("Audio type:", audio.currentSrc);
      console.log("Audio MIME type:", audio.type);
    },
    tryGetDuration() {
      const audio = this.$refs.audioElement;
      if (audio && !isNaN(audio.duration)) {
        this.audioDuration = audio.duration;
        this.totalDuration = this.formatTime(this.audioDuration);
      } else {
        this.totalDuration = "Unknown";
      }
    },
    setProgress(event) {
      this.updateAudioPosition(event.clientX);
    },
    handleTouchStart(event) {
      this.isSeeking = true;
      this.updateAudioPosition(event.touches[0].clientX);
    },
    handleTouchMove(event) {
      if (this.isSeeking) {
        this.updateAudioPosition(event.touches[0].clientX);
      }
    },
    handleTouchEnd() {
      this.isSeeking = false;
    },
    updateAudioPosition(clientX) {
      const audio = this.$refs.audioElement;
      const progressBar = this.$el.querySelector('.progress-bar');
      const rect = progressBar.getBoundingClientRect();
      const relativeX = clientX - rect.left;
      const percentageClicked = relativeX / progressBar.offsetWidth;

      if (percentageClicked >= 0 && percentageClicked <= 1) {
        this.progress = percentageClicked * 100;
        audio.currentTime = percentageClicked * this.audioDuration;
      }
    },
    formatTime(timeInSeconds) {
      if (isNaN(timeInSeconds) || !isFinite(timeInSeconds)) {
        return "00:00";
      }
      const minutes = Math.floor(timeInSeconds / 60)
          .toString()
          .padStart(2, "0");
      const seconds = Math.floor(timeInSeconds % 60)
          .toString()
          .padStart(2, "0");
      return `${minutes}:${seconds}`;
    },
    audioLoaded() {
      this.loadingError = null;
    },
    handleAudioError() {
      this.loadingError = "Failed to load audio. Please check your connection and try again.";
    },
    isAudio(fileUrl) {
      return /\.(weba|m4a|mp3|wav|ogg|opus)$/i.test(fileUrl);
    },
    getMediaUrl(fileUrl) {
      const accessToken = localStorage.getItem('access_token');
      if (this.isAudio(fileUrl)) {
        return `${this.baseURL}/uploads/MediaContent/${fileUrl}?token=${accessToken}`;
      }
      return fileUrl;
    },
    handleKeyboardControls(event) {
      if (event.code === 'Space') {
        event.preventDefault();
        this.togglePlayPause();
      }
    },
  },
};
</script>

<style scoped>
.audio-player {

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  min-width:  100%;


}

.controls {
  margin-bottom: 30px;
  position: relative;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(135deg, #FFDFDF, #DFDFFF);
  border-radius: 1rem;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0 1.5rem 1rem -1rem rgba(0, 0, 0, 0.5),
  0 1rem 1rem -1rem rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

/* Top Section for Play/Pause and Played Time */
.top-controls {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.top-controls p {
  margin-bottom: 0px;
  text-align: left;
}

.played {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: black;
}

.played img {
  width: 30px;
  margin-bottom: -15px;
  margin-left: -40px;
}

.play-pause-btn {
  background: white;
  border: none;
  outline: none;
  cursor: pointer;
  height: 50px;
  width: 50px;
  border-radius: 100px;
  box-shadow: 0 0.75rem 1rem -1rem rgba(0, 0, 0, 1),
  0 0rem 1.5rem 0 rgba(0, 0, 0, 0.1);

  /* Flexbox to center the icon */
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
}

.play-pause-icon {
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  padding-left: 0rem;
  box-sizing: border-box;
  object-fit: contain;
  margin-left: 3px;
  filter: brightness(0) saturate(100%) invert(41%) sepia(3%) saturate(1140%) hue-rotate(170deg) brightness(94%) contrast(94%);
}

/* Progress Bar at the bottom */
.progress-bar {
  width: calc(100% + 40px);
  height: 5px;
  background: rgba(150, 150, 150, 1);
  cursor: pointer;
  margin-bottom: 0;
}

.progress {
  height: 100%;
  background: rgb(106, 68, 126);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: calc(100% + 40px);
}

audio {
  display: none;
}
</style>


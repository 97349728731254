import { reactive } from 'vue';
import axios from 'axios';

const state = reactive({
  profile: null,
  loading: false,
  error: null,
});

const fetchProfile = async () => {
  state.loading = true;
  try {
    const accessToken = localStorage.getItem('access_token');
    const response = await axios.get('/users/profile', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    state.profile = response.data;
  } catch (error) {
    state.error = error;
    console.error('Error loading profile:', error);
  } finally {
    state.loading = false;
  }
};

export default {
  state,
  fetchProfile,
};

<template>
  <form @submit.prevent="handleSubmit">
    <div class="form-container">
      <div class="form-group" v-for="field in fields" :key="field.id">
        <input
            :type="field.type"
            :id="field.id"
            v-model="field.model"
            :placeholder="field.label"
            :required="field.required"
            class="form-input"
        />
      </div>
    </div>
    <div class="form-button">
      <button type="submit" class="submit-button">{{ buttonLabel }}</button>
    </div>
  </form>
</template>


<script>
export default {
  name: "FormInput",
  props: {
    fields: {
      type: Array,
      required: true
    },
    buttonLabel: {
      type: String,
      default: "Submit"
    },
    onSubmit: {
      type: Function,
      required: true
    }
  },
  methods: {
    handleSubmit() {
      const formData = this.fields.reduce((data, field) => {
        data[field.id] = field.model;
        return data;
      }, {});
      this.onSubmit(formData);
    }
  }
};
</script>


<style scoped>
.form-container {
  height: 250px;
}

.form-group {
  margin-bottom: 15px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-rendering: optimizeLegibility;
}

.form-input {
  width: 100%;
  background-color: rgba(217, 217, 217, 1);
  padding: 16px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 50px;
  height: 40px;
}

.form-input:focus {
  border-color: #7e57c2; /* Change border color on focus */
}

.form-button {
  margin-bottom: 10px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-rendering: optimizeLegibility;
}

.submit-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: rgba(97, 61, 105, 0.5);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  width: 100%;
  height: 40px;
  margin-top: 0px;
}

.submit-button:hover {
  background-color: rgba(142, 100, 149, 1);
}

@media screen and (max-width: 768px) {
  .form-container {
    height: 10rem;
  }

  .form-group {
    margin-bottom: 15px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    text-rendering: optimizeLegibility;
  }

  .form-input {
    width: 100%;
    background-color: rgba(217, 217, 217, 1);
    padding: 16px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 50px;
    height: 40px;
  }

  .form-input:focus {
    border-color: #7e57c2; /* Change border color on focus */
  }

  .form-button {
    margin-bottom: 10px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    text-rendering: optimizeLegibility;
  }

  .submit-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: rgba(97, 61, 105, 0.5);
    border: none;
    border-radius: 50px;
    cursor: pointer;
    width: 100%;
    height: 40px;
    margin-top: 0px;
  }

  .submit-button:hover {
    background-color: rgba(142, 100, 149, 1);
  }
}
</style>


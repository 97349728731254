<template>
  <div class="layout-container">
    <TopBar />
    <div class="line"></div>
    <div class="main-content">
      <LeftBar />
      <div class="rightbar">

        <router-view name="rightbar" @profile-image-updated="updateProfileImageUrl" />
        <router-view /> <!-- Main content router view -->
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from './TopBar.vue';
import LeftBar from './LeftBar.vue';
import axios from "axios";

export default {
  components: {
    TopBar,
    LeftBar,
  },
  setup() {
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
  },
};
</script>

<style scoped>
.layout-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Opera */
}

.topbar {
  height: 90px;
  margin-top: 0px;
  width: 100%;
  background-color: var(--topbar-background);
  justify-content: center;
  display: flex;
  z-index: 2000;
}

.main-content {
  display: flex;
  flex: 1;
  overflow: hidden; /* Prevents the main content from creating scrollbars */
}

.leftbar {
  width: 20%;
  min-width: 500px;
}

.rightbar {
  width: 80%;
  min-width: 800px;
  overflow: auto; /* Enable scrolling */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.rightbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.line {
  position: absolute;
  left: 0;
  margin-top: 90px;
  z-index: 10;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #623e6a 0%, #b48bbb 27%, #bf9bc5 51.5%, #b48bbb 76%, #623e6a 100%);
  box-shadow: 0 1px 8px 0px rgb(201, 201, 201);
}

@media screen and (max-width: 768px) {
  .layout-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Opera */
  }

  .topbar {
    height: 90px;
    margin-top: 0px;
    width: 100%;
    background-color: var(--topbar-background);
    justify-content: center;
    display: flex;
    z-index: 2000;
  }

  .main-content {
    display: flex;
    flex: 1;
    overflow: hidden; /* Prevents the main content from creating scrollbars */
  }

  .leftbar {
    width: 0%;
    min-width: 0px;
    content-visibility: hidden;
  }

  .rightbar {
    min-width: 100%;
    overflow: auto; /* Enable scrolling */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

  }

  .rightbar::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .line {
    position: absolute;
    left: 0;
    margin-top: 70px;

    width: 100%;
    z-index: 10;
    height: 2px;
    background: linear-gradient(90deg, #623e6a 0%, #b48bbb 27%, #bf9bc5 51.5%, #b48bbb 76%, #623e6a 100%);
    box-shadow: 0 1px 8px 0px rgb(201, 201, 201);
  }

  .topbar {
    height: 70px;
    margin-top: 0px;
    width: 100%;
    background-color: var(--topbar-background);
    justify-content: center;
    display: flex;
    z-index: 2000;
  }
}

</style>

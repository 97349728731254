<template>
  <BaseError
      :errorCode="500"
      errorMessage="Internal Server Error. Please try again later."
  />
</template>

<script>
import BaseError from './BaseError.vue';

export default {
  name: 'ServerError',
  components: { BaseError }
}
</script>
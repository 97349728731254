<template>
  <div class="subscription-page" :style="{ minHeight: containerHeight }">
    <h2>Subscriptions</h2>
    <div v-if="loading">Loading...</div>
    <div v-if="error">{{ error.message }}</div>
    <div v-if="!loading && profile">
      <div v-if="isCreator">
        <h3>Creator Dashboard</h3>
        <div v-if="creatorDashboard">
          <div class="dashboard-stats">
            <div class="stat-container">
              <h4>This Month's Earnings</h4>
              <p class="stat-value">${{ (creatorDashboard.totalEarnings || 0).toFixed(2) }}</p>
            </div>
            <div class="stat-container">
              <h4>Last Month's Earnings</h4>
              <p class="stat-value">${{ (creatorDashboard.lastMonthEarnings || 0).toFixed(2) }}</p>
            </div>
            <div class="stat-container">
              <h4>Percentage Change</h4>
              <p class="stat-value" :class="{ 'positive': (creatorDashboard.percentageChange || 0) >= 0, 'negative': (creatorDashboard.percentageChange || 0) < 0 }">
                {{ (creatorDashboard.percentageChange || 0).toFixed(2) }}%
              </p>
            </div>
          </div>

          <div class="dashboard-details">
            <div class="earnings-chart">
              <h4>Monthly Earnings (Last 12 Months)</h4>
              <canvas ref="earningsChart"></canvas>
            </div>

            <div class="top-tippers">
              <h4>Top Tipping Subscribers</h4>
              <table v-if="creatorDashboard.topTippers && creatorDashboard.topTippers.length">
                <thead>
                <tr>
                  <th>Username</th>
                  <th>Total Tips</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="tipper in creatorDashboard.topTippers" :key="tipper.username">
                  <td>{{ tipper.username }}</td>
                  <td>${{ (tipper.totalTips || 0).toFixed(2) }}</td>
                </tr>
                </tbody>
              </table>
              <p v-else>No top tippers data available.</p>
            </div>
          </div>
        </div>
        <div v-else>No dashboard data available.</div>

        <h3>Your Subscribers</h3>
        <ul v-if="subscribers.length" class="subscription-list">
          <li v-for="subscriber in subscribers" :key="subscriber.id" class="subscription-item">
            <div class="profile-image">
              <img :src="getProfileImageUrl(subscriber.profileImage)" alt="Profile" v-if="subscriber.profileImage" />
              <div v-else class="placeholder-image"></div>
            </div>
            <div class="creator-info">
              <span class="creator-name">{{ subscriber.username }}</span>

            </div>
          </li>
        </ul>
        <p v-else>You have no subscribers yet.</p>
      </div>
      <div v-else>
        <h3>Creators You Are Subscribed To</h3>
        <ul v-if="subscriptions.length" class="subscription-list">
          <li v-for="subscription in subscriptions" :key="subscription.id" class="subscription-item">
            <div class="profile-image">
              <img :src="getProfileImageUrl(subscription.profileImage)" alt="Profile" v-if="subscription.profileImage" />
              <div v-else class="placeholder-image"></div>
            </div>
            <div class="creator-info">
              <span class="creator-name">{{ subscription.username }}</span>
            </div>
            <div class="subscription-price">
              {{ subscription.subscriptionPrice !== null && subscription.subscriptionPrice !== 0 ? `$${subscription.subscriptionPrice.toFixed(2)} / month` : 'Free' }}
            </div>
          </li>
        </ul>
        <p v-else>You are not subscribed to any creators yet.</p>
      </div>
    </div>
    <div class="bottom"></div>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch, nextTick, onBeforeUnmount } from 'vue';
import profileStore from '@/store/profileStore';
import axios from 'axios';
import Chart from 'chart.js/auto';

export default {
  name: 'SubscriptionPage',
  setup() {
    const subscribers = ref([]);
    const subscriptions = ref([]);
    const creatorDashboard = ref(null);
    const earningsChart = ref(null);
    const earningsChartRef = ref(null);
    const vh = ref(0);
    const isMobile = ref(false);
    const dashboardError = ref(null);

    const profile = computed(() => profileStore.state.profile);
    const loading = computed(() => profileStore.state.loading);
    const error = computed(() => profileStore.state.error || dashboardError.value);
    const isCreator = computed(() => profile.value && profile.value.role === 2);

    const containerHeight = computed(() => `calc(${100 * vh.value}px - 80px)`);

    const getProfileImageUrl = (filename) => {
      if (!filename) return '';
      const accessToken = localStorage.getItem('access_token');
      return `${axios.defaults.baseURL}/uploads/profile_images/${filename}?token=${accessToken}`;
    };

    const fetchSubscribers = async () => {
      try {
        const response = await axios.get('/subscriptions/subscribers', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        });
        subscribers.value = response.data;
      } catch (error) {
        console.error('Error fetching subscribers:', error);
        dashboardError.value = error;
      }
    };

    const fetchSubscriptions = async () => {
      try {
        const response = await axios.get('/subscriptions/subscribed', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        });
        subscriptions.value = response.data;
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
        dashboardError.value = error;
      }
    };

    const fetchCreatorDashboard = async () => {
      try {
        const response = await axios.get('/creator/dashboard', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        });
        creatorDashboard.value = response.data;
        await nextTick();
        renderEarningsChart();
      } catch (error) {
        console.error('Error fetching creator dashboard:', error);
        dashboardError.value = error;
      }
    };

    const renderEarningsChart = () => {
      if (earningsChart.value) {
        earningsChart.value.destroy(); // Destroy previous chart instance if it exists
      }

      if (earningsChartRef.value && creatorDashboard.value && creatorDashboard.value.monthlyEarnings) {
        console.log("Canvas element:", earningsChartRef.value); // Check if canvas is available
        console.log("Monthly earnings data:", creatorDashboard.value.monthlyEarnings); // Check data format

        const ctx = earningsChartRef.value.getContext('2d');
        earningsChart.value = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: creatorDashboard.value.monthlyEarnings.map(e => e.month),
            datasets: [{
              label: 'Monthly Earnings',
              data: creatorDashboard.value.monthlyEarnings.map(e => e.earnings),
              backgroundColor: 'rgba(75, 192, 192, 0.6)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1
            }]
          },
          options: {
            responsive: true,
            scales: {
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: 'Earnings ($)'
                }
              },
              x: {
                title: {
                  display: true,
                  text: 'Month'
                }
              }
            }
          }
        });
      } else {
        console.error("Error: Canvas element or data not available.");
      }
    };

    const checkMobile = () => {
      isMobile.value = window.innerWidth <= 768;
    };

    const setViewportHeight = () => {
      vh.value = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh.value}px`);
    };

    const handleResize = () => {
      checkMobile();
      setViewportHeight();
    };

    onMounted(async () => {
      await profileStore.fetchProfile();
      if (isCreator.value) {
        await fetchSubscribers();
        await fetchCreatorDashboard();
      } else {
        await fetchSubscriptions();
      }
      checkMobile();
      setViewportHeight();
      window.addEventListener('resize', handleResize);
    });

    watch(creatorDashboard, () => {
      if (creatorDashboard.value) {
        nextTick(() => renderEarningsChart());
      }
    });
    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleResize);
    });
    return {
      subscribers,
      subscriptions,
      profile,
      loading,
      error,
      isCreator,
      creatorDashboard,
      earningsChartRef,
      getProfileImageUrl,
      containerHeight,
      isMobile
    };
  }
};
</script>


<style scoped>
.subscription-page {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  max-width: 800px;
  margin: 0 auto;
  min-height: calc(var(--vh, 1vh) * 100 - 80px);
}

h2, h3 {
  color: var(--topbar-h1-color, #ffffff);
  margin-bottom: 20px;
}

/* Creator Dashboard Styles */
.dashboard-stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}

.stat-container {
  flex: 1 1 200px;
  text-align: center;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 10px;
  min-width: 150px;
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}

.stat-container h4 {
  margin-top: 0;
  font-size: 0.9em;
  color: #666;
}

.stat-value {
  font-size: 1.2em;
  font-weight: bold;
  margin: 10px 0 0 0;
}

.positive {
  color: green;
}

.negative {
  color: red;
}

.dashboard-details {
  margin-top: 20px;
}

.earnings-chart {
  margin-bottom: 20px;
}

.top-tippers table {
  width: 100%;
  border-collapse: collapse;
}

.top-tippers th, .top-tippers td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.top-tippers th {
  background-color: #f2f2f2;
}

/* Consumer Subscription List Styles */
.subscription-list {
  list-style-type: none;
  padding: 0;
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}

.subscription-item {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-top: 1px solid rgba(142, 100, 149, 0.3);
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}

.subscription-item:last-child {
  border-bottom: 1px solid rgba(142, 100, 149, 0.3);
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}

.profile-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  flex-shrink: 0;
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.placeholder-image {
  width: 100%;
  height: 100%;
  background-color: rgba(142, 100, 149, 0.2);
}

.creator-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.creator-name {
  font-size: 1.2em;
  font-weight: bold;
  color: var(--topbar-h1-color, #ffffff);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.creator-email {
  font-size: 0.9em;
  color: rgba(255, 255, 255, 0.7);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subscription-price {
  font-weight: bold;
  color: var(--subscription-h1-color, #ffffff);
  background-color: rgba(61, 41, 70, 0.8);
  padding: 5px 10px;
  border-radius: 15px;
  white-space: nowrap;
  margin-left: 10px;
  min-width: 120px;
}

@media (max-width: 768px) {
  .subscription-page {
    min-height: calc(var(--vh, 1vh) * 100 - 80px);
  }

  .dashboard-stats {
    flex-direction: column;
  }

  .stat-container {
    margin: 5px 0;
  }

  .subscription-item {
    flex-wrap: wrap;
  }

  .profile-image {
    margin-bottom: 10px;
  }

  .creator-info {
    width: calc(100% - 80px);
    margin-bottom: 10px;
  }

  .subscription-price {
    width: 100%;
    text-align: right;
    margin-left: 0;
  }
  .bottom {
    padding-bottom: 90px;
  }
}
</style>

<template>
  <div class="message-chat" style="height:100dvh">
    <div class="chat-header">
      <img class="BackButton" @click="backToOverview" :src="BackIcon" alt="Back Icon"/>
      <div class="NameOfMessage">
        <router-link :to="{ name: 'creatorFeed', params: { id: creatorName }}" class="creator-name">
          {{ selectedConversation.other_user }}
        </router-link>
      </div>
    </div>

    <div class="chat-body">
      <ChatMessages
          v-if="selectedConversation.id"
          class="chat-messages"
          ref="chatMessages"
          :conversationId="selectedConversation.id"
          :userId="userId"
      />
    </div>

    <div v-if="canSendMessage" class="chat-input">
      <textarea v-model="newMessage" placeholder="Type a message..." @input="adjustTextareaHeight"></textarea>
      <button @click="sendMessage" :disabled="!newMessage.trim()">Send</button>
    </div>
    <div v-else class="chat-input locked">
      <div class="locked-message">{{ lockedMessage }}</div>
    </div>
  </div>
  <div class="bottom">
  </div>
</template>

<script>
import axios from 'axios';
import { useRouter } from 'vue-router';
import ChatMessages from './ChatMessages.vue';
import eventBus from '@/utils/eventBus.js';
import BackIcon from '@/assets/images/back-svgrepo-com.svg';

axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

export default {
  components: {
    ChatMessages
  },
  props: {
    selectedConversation: Object
  },
  setup() {
    const router = useRouter();

    const navigateToCreatorFeed = (creatorName) => {
      router.push({name: 'creatorFeed', params: {id: creatorName}});
    };

    return {
      navigateToCreatorFeed
    };
  },
  data() {
    return {
      messages: [],
      newMessage: '',
      userId: null,
      BackIcon: BackIcon,
      creatorName: '',
      error: null,
      userRole: null,
      isSubscribed: false,
      isLoading: true
    };
  },
  computed: {
    canSendMessage() {
      if (this.isLoading) return false;
      return this.isSubscribed;
    },
    lockedMessage() {
      if (this.isCreator) {
        return `${this.selectedConversation.other_user} is not subscribed to you`;
      } else {
        return `Subscribe to ${this.selectedConversation.other_user} to send messages`;
      }
    },
    isCreator() {
      return this.userRole === 2;
    }
  },
  watch: {
    selectedConversation: {
      immediate: true,
      handler(newValue) {
        if (newValue && newValue.id) {
          this.creatorName = newValue.other_user;
          this.checkSubscriptionStatus();
        } else {
          this.creatorName = '';
        }
      }
    }
  },
  async created() {
    await this.fetchUserProfile();
    this.checkSubscriptionStatus();
  },
  methods: {
    async fetchUserProfile() {
      try {
        const response = await axios.get('/users/profile', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        });
        this.userId = response.data.user_id;
        this.userRole = response.data.role;
        console.log('User profile:', response.data);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    },
    async checkSubscriptionStatus() {
      this.isLoading = true;
      try {
        let response;
        const accessToken = localStorage.getItem('access_token');
        if (this.isCreator) {
          response = await axios.get(`/subscriptions/check/${this.selectedConversation.other_user}/to-creator`, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });
        } else {
          response = await axios.get(`/subscriptions/check/${this.selectedConversation.other_user}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });
        }
        this.isSubscribed = response.data.is_subscribed;
        console.log('Subscription status:', this.isSubscribed);
      } catch (error) {
        console.error('Error checking subscription status:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async sendMessage() {
      if (!this.canSendMessage || !this.newMessage.trim()) return;

      try {
        const messageData = {
          conversation_id: this.selectedConversation.id,
          content: this.newMessage
        };
        console.log('Sending message data:', messageData);

        const accessToken = localStorage.getItem('access_token');

        // Send the request with the Authorization header
        const response = await axios.post('/messages', messageData, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });

        console.log('Message sent, response:', response.data);
        this.newMessage = '';
        if (this.$refs.chatMessages) {
          this.$refs.chatMessages.updateMessages(response.data);
        }
        eventBus.emit('message-sent');
        this.error = null;
      } catch (error) {
        console.error('Error sending message:', error);
        this.error = 'Failed to send message. Please try again.';
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
        }
      }
    },
    backToOverview() {
      this.$emit('back-to-overview');
    },
    adjustTextareaHeight(event) {
      const textarea = event.target;
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    }
  }
};
</script>


<style scoped>
.NameOfMessage {
  padding-right: 50px;
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  width: 100%; /* Take up full width of the container */
  color: #3d2946;
  text-align: center;
}

.chat-input.locked {
  background-color: rgba(61, 41, 70, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  border-top: 1px solid rgba(61, 41, 70, 0.4);
}

.locked-message {
  color: var(--chatinput-color);
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  text-align: center;
}
.message-chat {
  display: flex;
  flex-direction: column;
  height: calc(100% - 20px);
  overflow: hidden;
  max-width: 70%;
}

.chat-header {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding-left: 10px;
  border-bottom: 1px solid rgba(61, 41, 70, 0.4);
  background-color: var(--bg-color);
  z-index: 1;
}

.BackButton {
  width: 40px;
  margin-right: 10px;
  cursor: pointer;
  float: left;
  height: 70px;
  transition: width 0.3s ease, border-radius 0.3s ease;
  overflow: hidden;
  position: relative;
  display: flex;
  filter: brightness(0) saturate(100%) invert(16%) sepia(5%) saturate(5050%) hue-rotate(237deg) brightness(97%) contrast(92%);
}

.creator-name {
  color: var(--topbar-h1-color);
  font-family: 'Rouge Script', cursive;
  font-size: 30px;
  font-weight: 200;
  text-decoration: none;
}

.chat-body {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
}

.chat-input {
  flex-shrink: 0;
  display: flex;
  padding: 10px;
  border-top: 1px solid rgba(61, 41, 70, 0.4);
  background-color: var(--bg-color);
}

.chat-input textarea {
  flex: 1;
  resize: none;
  min-height: 40px;
  max-height: 120px;
  padding: 10px;
  border: 1px solid rgba(61, 41, 70, 1);
  border-radius: 20px;
  background-color: transparent;
  color: var(--chatinput-color);
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

.chat-input button {
  margin-left: 10px;
  padding: 0 20px;
  background-color: rgba(61, 41, 70, 1);
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
}

.chat-input button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .message-chat {

    max-width: 100%;
    font-size: 16px;
  }

  .chat-header {
    position: sticky;
    top: 0;
  }

  .chat-input {
    position: sticky;
    bottom: 0;

    font-size: 16px;
  }

  .bottom {

  }
}
</style>
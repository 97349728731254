<template lang="en">
  <div class="profile-image-container" :style="profileImageStyle">
    <img v-if="resolvedProfileImageUrl" :src="resolvedProfileImageUrl" alt="Profile Image" class="profile-photo"/>
  </div>
</template>

<script>
export default {
  name: 'ProfileImage',
  props: {
    profileImageUrl: {
      type: String,
      required: false
    },
    userRole: {
      type: Number,
      required: true // Assuming userRole will be passed to this component
    }
  },
  computed: {
    resolvedProfileImageUrl() {
      if (this.profileImageUrl) {
        return this.profileImageUrl;
      }

      // If profileImageUrl is null, resolve based on userRole
      if (this.userRole === 2) {
        // Creator role
        return require('@/assets/images/picreator.webp');
      } else if (this.userRole === 1) {
        // Consumer role
        return require('@/assets/images/piconsumer.webp');
      }

      // Default image for users with neither creator nor consumer role
      return require('@/assets/images/360_F_549983970_bRCkYfk0P6PP5fKbMhZMIb07mCJ6esXL.jpg');
    },
    profileImageStyle() {
      return this.profileImageUrl
          ? {}
          : {
            backgroundColor: '#FAF9F6',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100px',
            height: '100px',
            borderRadius: '50%'
          };
    }
  }
};
</script>

<style scoped>
.profile-image-container {
  margin-top: 25px;
  margin-bottom: 25px;
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.profile-photo {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
}
</style>

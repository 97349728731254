import { createRouter, createWebHistory } from "vue-router";
import LoginSite from "@/components/LoginSite.vue";
import SignupPage from "@/components/SignupPage.vue";
import ConsumerFeed from "@/components/ConsumerFeed.vue";
import CreatorFeed from "@/components/CreatorFeed.vue";
import SubscriptionPage from "@/components/SubscriptionPage.vue";
import SettingsPage from "@/components/SettingsPage.vue";
import LayoutPage from "@/components/LayoutPage.vue";
import MessagesContainer from "@/components/MessagesContainer.vue";
import NotFoundError from "@/components/errors/NotFoundError.vue";
import ServerError from "@/components/errors/ServerError.vue";
import BadRequestError from "@/components/errors/BadRequestError.vue";
import UnauthorizedError from "@/components/errors/UnauthorizedError.vue";
import ForbiddenError from "@/components/errors/ForbiddenError.vue";
import ServiceUnavailableError from "@/components/errors/ServiceUnavailableError.vue";

const routes = [
  { path: "/", name: "login", component: LoginSite },
  { path: "/signup", name: "signup", component: SignupPage },
  {
    path: "/",
    component: LayoutPage,
    children: [
      { path: "Feed", name: "Feed", component: ConsumerFeed },
      { path: "Subscriptions", name: "subscriptionPage", component: SubscriptionPage },
      { path: ":id", name: "creatorFeed", component: CreatorFeed },
      { path: "Settings", components: { rightbar: SettingsPage } },
      { path: "Messages", components: { rightbar: MessagesContainer } },
      { path: "400", name: "badRequest", component: BadRequestError },
      { path: "401", name: "unauthorized", component: UnauthorizedError },
      { path: "403", name: "forbidden", component: ForbiddenError },
      { path: "404", name: "notFound", component: NotFoundError },
      { path: "500", name: "serverError", component: ServerError },
      { path: "503", name: "serviceUnavailable", component: ServiceUnavailableError },
    ],
  },
  // Catch-all route for 404 errors
  { path: "/:pathMatch(.*)*", redirect: { name: 'notFound' } },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Navigation guard for error handling
router.beforeEach((to, from, next) => {
  const errorRoutes = ['badRequest', 'unauthorized', 'forbidden', 'notFound', 'serverError', 'serviceUnavailable'];
  if (errorRoutes.includes(to.name)) {
    // If it's already an error route, proceed
    next();
  } else if (!to.matched.length) {
    // If no route matched, redirect to 404
    next({ name: 'notFound' });
  } else {
    // Otherwise, proceed as normal
    next();
  }
});

export default router;
<template lang="en">
  <div class="username-container">
    <div v-if="userName" class="top-text">{{ userName }}</div>        
  </div>
</template>

<script>
export default {
  name: 'UserName',
  props: {
    userName: {
      type: String,
      required: true
    }
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rouge+Script&display=swap');

.username-container {
  color: var(--username-container-color);
  font-family: 'Rouge Script', cursive;
  font-size: 2rem;
  font-weight: 100;
}

</style>

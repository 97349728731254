<template>
  <div class="error-page">
    <div class="error-content">
      <div class="error-icon">!</div>
      <h1>Error {{ errorCode }}</h1>
      <p>{{ errorMessage }}</p>
      <button @click="goHome" class="home-button">Go to Home</button>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  name: 'BaseError',
  props: {
    errorCode: {
      type: Number,
      required: true
    },
    errorMessage: {
      type: String,
      required: true
    }
  },
  setup() {
    const router = useRouter();

    const goHome = () => {
      router.push({name: 'Feed'});
    };

    return {goHome};
  }
}
</script>

<style scoped>
.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: var(--background-gradient);
}

.error-content {
  text-align: center;
  padding: 2rem;
  background-color: rgba(51, 38, 58, 0.3);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.error-icon {
  font-size: 4rem;
  color: var(--topbar-h1-color);
  margin-bottom: 1rem;
}

h1 {
  font-size: 2.5rem;
  color: var(--topbar-h1-color);
  margin-bottom: 1rem;
}

p {
  font-size: 1.2rem;
  color: var(--message-preview-color);
  margin-bottom: 2rem;
}

.home-button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: rgba(142, 100, 149, 1);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.home-button:hover {
  background-color: rgba(97, 61, 105, 0.8);
}
</style>
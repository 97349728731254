<template>
  <BaseError
      :errorCode="400"
      errorMessage="Bad Request: The server cannot process your request due to invalid input."
  />
</template>

<script>
import BaseError from './BaseError.vue';

export default {
  name: 'BadRequestError',
  components: {BaseError}
}
</script>
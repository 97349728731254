<template>
  <BaseError
      :errorCode="403"
      errorMessage="Forbidden: You don't have permission to access this resource."
  />
</template>

<script>
import BaseError from './BaseError.vue';

export default {
  name: 'ForbiddenError',
  components: {BaseError}
}
</script>
<template>
  <BaseError
      :errorCode="401"
      errorMessage="Unauthorized: Please log in to access this resource."
  />
</template>

<script>
import BaseError from './BaseError.vue';

export default {
  name: 'UnauthorizedError',
  components: { BaseError }
}
</script>
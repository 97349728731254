<template>
  <div class="chat-messages" ref="chatContainer" @scroll="handleScroll">
    <div v-for="message in visibleMessages" :key="message.id"
         :class="['message', message.sender_id == userId ? 'my-message' : 'their-message', {'unread': !message.is_read}]">
      {{ message.content }}
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    conversationId: Number,
    userId: Number,
  },
  data() {
    return {
      visibleMessages: [],
      allMessages: [],
      messageBatchSize: 20,
      isLoading: false,
      reachedEnd: false,
      previousScrollHeight: 0,
    };
  },
  watch: {
    conversationId: {
      immediate: true,
      handler(newConversationId) {
        if (newConversationId) {
          this.reachedEnd = false;
          this.loadMessages(true);
        } else {
          this.visibleMessages = [];
          this.allMessages = [];
        }
      }
    }
  },
  methods: {
    async loadMessages(initial = false) {
      if (this.isLoading || this.reachedEnd || !this.conversationId) return;

      this.isLoading = true;
      const offset = initial ? 0 : this.allMessages.length;
      const accessToken = localStorage.getItem('access_token'); // Get the token from local storage

      try {
        const response = await axios.get(`/conversations/${this.conversationId}/messages`, {
          params: { offset, limit: this.messageBatchSize },
          headers: {
            Authorization: `Bearer ${accessToken}` // Set the token in the Authorization header
          }
        });

        const newMessages = response.data.reverse();
        if (newMessages.length < this.messageBatchSize) {
          this.reachedEnd = true;
        }

        if (initial) {
          this.allMessages = newMessages;
          this.visibleMessages = newMessages;
        } else {
          this.allMessages = [...newMessages, ...this.allMessages];
          this.visibleMessages = this.allMessages;
        }

        this.$nextTick(() => {
          if (initial) {
            this.scrollToBottom();
          } else {
            const chatContainer = this.$refs.chatContainer;
            if (chatContainer) {
              chatContainer.scrollTop = chatContainer.scrollHeight - this.previousScrollHeight;
            }
          }
        });
      } catch (error) {
        console.error('Error fetching messages:', error);
      } finally {
        this.isLoading = false;
      }
    },
    handleScroll() {
      const chatContainer = this.$refs.chatContainer;
      if (chatContainer && chatContainer.scrollTop === 0 && !this.isLoading) {
        this.previousScrollHeight = chatContainer.scrollHeight;
        this.loadMessages();
      }
    },
    scrollToBottom() {
      const chatContainer = this.$refs.chatContainer;
      if (chatContainer) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    },
    updateMessages(newMessage) {
      this.allMessages.push(newMessage);
      this.visibleMessages = this.allMessages.slice(-this.messageBatchSize);
      this.$nextTick(() => this.scrollToBottom());
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700&family=Rouge+Script&display=swap');

.chat-messages {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px;
  height: 100%;
}

.message {
  min-width: 60%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 15px;
  max-width: 70%;
  word-wrap: break-word;
  font-size: 0.8rem;
}

.my-message {
  text-align: right;
  font-family: 'Roboto', sans-serif;
  align-self: flex-end;
  background-color: rgba(61, 41, 70, 1);
  color: white;
  border-bottom-right-radius: 0;
}

.their-message {
  font-family: 'Roboto', sans-serif;
  text-align: left;
  align-self: flex-start;
  color: white;
  background-color: rgba(142, 100, 149, 1);
  border-bottom-left-radius: 0;
}

.unread {
  font-weight: normal;
}
</style>

<template lang="en">
  <div class="content">
    <div class="logo-container">
      <img alt="App logo" src="../../assets/images/logosmall1.webp" loading="lazy" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppLogo',
};
</script>
<style scoped>
.logo-container {
  position: relative;
  top: 80; /* Position at the very top */
  left: 15px;
  width: 150px;
  height: 150px;
  z-index: 4000; /* Set a high z-index to ensure it's always on top */
  background: linear-gradient(180deg, #00030E 0%, #1B1628 18%, #2E2033 34%, #221A2B 46%, #161322 56%, #090A17 70.5%, #050712 83.5%, #00030E 100%, 0);
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-container img {
  width: 100%;
  height: auto;
  display: block;
}

.content {
  width: 100%;
  height: 150px;
  margin-top: -50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
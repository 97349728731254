<template>
  <div class="terms-overlay">
    <div class="terms-content">
      <button class="close-button" @click="$emit('close')" aria-label="Close Terms and Conditions">×</button>

      <!-- Logo centered at the top -->
      <div class="logo-container">
        <img src="/logo.png" alt="DomSphere Logo" class="logo" />
      </div>

      <h1>Terms and Conditions</h1>
      <div class="terms-text">
        <!-- Replace with your actual Terms and Conditions -->
        <p>
          Welcome to DomSphere! By using our platform, you agree to the following terms and conditions. Please read these terms carefully before using our services.
        </p>
        <h2>Subscription and One-Time Payment Policy</h2>
        <p>
          All subscriptions and one-time payments are final and non-refundable. However, if you experience a genuine technical issue or accidental charge, you may file a complaint at <a href="mailto:info@domsphere.com">info@domsphere.com</a>. Our team will review the complaint and may issue refunds at our sole discretion.
        </p>
        <h2>Cancellation Policy</h2>
        <p>
          You may cancel your subscription at any time via the creator's profile page or your subscriptions management dashboard. You will continue to have access to the creator's content until the end of the current subscription period.
        </p>
        <h2>Chargebacks</h2>
        <p>
          Filing unauthorized chargebacks is prohibited. Please contact our support team to resolve any disputes before initiating a chargeback with your bank.
        </p>
        <p>
          For full details of our terms and conditions, please visit our <a href="#">full terms page</a>.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsAndConditions',
};
</script>

<style scoped>
/* Terms Overlay styling */
.terms-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.terms-content {
  background-color: white;
  width: 80%;
  height: 80%;
  padding: 20px;
  overflow-y: auto;
  position: relative;
}

/* Close button styling */
.close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 50px;
  cursor: pointer;
  border: none;
  background: none;
  color: #333;
}

/* Logo container styling */
.logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.logo {
  width: 100px;
  height: 100px;
}

/* Text styling */
h1 {
  text-align: center;
  color: #3d2946;
}

h2 {
  margin-top: 20px;
  color: #3d2946;
}

.terms-text {
  color: #3d2946;
  font-size: 16px;
  line-height: 1.5;
}

.terms-text a {
  color: #613d69ff;
}
</style>

<template>
  <div class="settingscontainer" :style="{ minHeight: containerHeight }">
  <div class="settingswrapper">
      <h2>Settings</h2>

      <!-- Theme Section -->
      <div class="section">
        <h3>Change Theme</h3>
        <button @click="toggleTheme">{{ themeButtonText }}</button>
      </div>

      <!-- Username Section -->
      <div class="section">
        <h3>Current Username</h3>
        <p>{{ currentUsername }}</p>
        <h3>Change Username</h3>
        <form @submit.prevent="changeUsername">
          <div>
            <label for="new_username">New Username:</label>
            <input type="text" v-model="newUsername" required />
          </div>
          <button type="submit">Change Username</button>
        </form>
      </div>

      <!-- Email Section -->
      <div class="section">
        <h3>Current Email</h3>
        <p>{{ currentEmail }}</p>
        <h3>Change Email</h3>
        <form @submit.prevent="changeEmail">
          <div>
            <label for="new_email">New Email:</label>
            <input type="email" v-model="newEmail" required />
          </div>
          <button type="submit">Change Email</button>
        </form>
      </div>

      <!-- Password Section -->
      <div class="section">
        <h3>Change Password</h3>
        <form @submit.prevent="changePassword">
          <div>
            <label for="old_password">Old Password:</label>
            <input type="password" v-model="oldPassword" required />
          </div>
          <div>
            <label for="new_password">New Password:</label>
            <input type="password" v-model="newPassword" required />
          </div>
          <div>
            <label for="confirm_new_password">Confirm New Password:</label>
            <input type="password" v-model="confirmNewPassword" required />
          </div>
          <button type="submit">Change Password</button>
        </form>
      </div>

      <!-- Description Section (for Creator) -->
      <div v-if="isCreator" class="section">
        <h3>Update Description</h3>
        <form @submit.prevent="updateDescription">
          <div>
            <textarea v-model="description" required></textarea>
          </div>
          <button type="submit">Update Description</button>
        </form>
      </div>

      <!-- Profile Image Section -->
      <div class="section">
        <h3>Current Profile Image</h3>
        <!-- Display current profile image if available -->
        <div v-if="currentProfileImage" class="profile-image-wrapper">
          <img :src="currentProfileImage" alt="Current Profile Image" class="profile-image-preview" />
        </div>

        <h3>Change Profile Image</h3>
        <upload-settings
            :isOpen="isProfileImageModalOpen"
            @close="closeProfileImageModal"
            @upload-success="handleProfileImageUpload"
            uploadUrl="/settings/upload_profile_image"
            successMessage="Profile image uploaded successfully"
        />
        <button @click="openProfileImageModal">Change Profile Image</button>
      </div>

      <!-- Banner Image Section (for Creator) -->
      <div v-if="isCreator" class="section">
        <h3>Change Banner Image</h3>
        <upload-settings
            :isOpen="isBannerImageModalOpen"
            @close="closeBannerImageModal"
            @upload-success="handleBannerImageUpload"
            uploadUrl="/settings/upload_banner_image"
            successMessage="Banner image uploaded successfully"
        />
        <button @click="openBannerImageModal">Change Banner Image</button>
      </div>

      <!-- Subscription Price Section (for Creator) -->
      <div v-if="isCreator" class="section">
        <h3>Current Subscription Price</h3>
        <p>{{ currentSubscriptionPrice }}</p>
        <h3>Update Subscription Price</h3>
        <form @submit.prevent="updateSubscriptionPrice">
          <div>
            <input
                type="text"
                v-model="subscriptionPrice"
                required
                @input="validateSubscriptionPrice"
                maxlength="10"
                placeholder="Enter numeric value"
            />
          </div>
          <button type="submit">Update Subscription Price</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import eventBus from '../utils/eventBus';
import UploadSettings from '@/components/UploadSettings.vue';

export default {
  name: 'SettingsPage',
  components: {
    UploadSettings,
  },
  data() {
    return {
      isCreator: false,
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      newUsername: '',
      newEmail: '',
      description: '',
      subscriptionPrice: '',
      currentUsername: '',
      currentEmail: '',
      currentDescription: '',
      currentSubscriptionPrice: 0,
      currentTheme: '',
      themeButtonText: 'Switch to Dark Mode',
      isProfileImageModalOpen: false,
      isBannerImageModalOpen: false,
      currentProfileImage: '', // Holds the URL of the current profile image
      vh: 0,
      isMobile: false,
    };
  },
  computed: {
    containerHeight() {
      return `calc(${100 * this.vh}px - 80px)`;
    },
  },
  created() {
    const savedTheme = localStorage.getItem('theme');
    this.currentTheme = savedTheme || 'light';
    this.themeButtonText = this.currentTheme === 'light' ? 'Switch to Dark Mode' : 'Switch to Light Mode';
    this.fetchUserDetails();
    this.checkMobile();
    this.setViewportHeight();
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    validateSubscriptionPrice() {
      this.subscriptionPrice = this.subscriptionPrice.replace(/[^0-9]/g, '');
    },
    async fetchUserDetails() {
      try {
        const response = await axios.get('/users/profile', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        });
        const user = response.data;
        const accessToken = localStorage.getItem('access_token');

        this.isCreator = user.role === 2;
        this.currentUsername = user.username;
        this.currentEmail = user.email;
        this.currentDescription = user.description || '';
        this.currentSubscriptionPrice = user.subscriptionPrice || 0;

        // Check if the profile image exists before appending the token
        if (user.profile_image) {
          this.currentProfileImage = `${user.profile_image}?token=${accessToken}`;
        } else {
          // Assign a fallback image based on the user’s role if profile_image is null
          if (user.role === 2) {
            this.currentProfileImage = require('@/assets/images/picreator.webp');
          } else if (user.role === 1) {
            this.currentProfileImage = require('@/assets/images/piconsumer.webp');
          } else {
            this.currentProfileImage = require('@/assets/images/360_F_549983970_bRCkYfk0P6PP5fKbMhZMIb07mCJ6esXL.jpg');
          }
        }
      } catch (error) {
        console.error('Failed to fetch user details', error);
      }
    }
,
    openProfileImageModal() {
      this.isProfileImageModalOpen = true;
    },
    closeProfileImageModal() {
      this.isProfileImageModalOpen = false;
    },
    openBannerImageModal() {
      this.isBannerImageModalOpen = true;
    },
    closeBannerImageModal() {
      this.isBannerImageModalOpen = false;
    },
    async handleProfileImageUpload() {
      this.isProfileImageModalOpen = false;
      alert('Profile image uploaded successfully');

      // Refetch user details to update the displayed profile image
      await this.fetchUserDetails();

      // Emit the updated profile image URL to update LeftBar immediately
      eventBus.emit('profile-image-updated', this.currentProfileImage);
    },
    async handleBannerImageUpload() {
      this.isBannerImageModalOpen = false;
      alert('Banner image uploaded successfully');
    },
    async updateSubscriptionPrice() {
      try {
        const response = await axios.post(
            '/settings/update_subscription_price',
            { subscription_price: this.subscriptionPrice },
            { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }
        );
        alert(response.data.message);
        this.currentSubscriptionPrice = this.subscriptionPrice;
      } catch (error) {
        alert(error.response?.data?.message || 'Failed to update subscription price');
      }
    },
    async changePassword() {
      if (this.newPassword !== this.confirmNewPassword) {
        alert('New passwords do not match');
        return;
      }
      try {
        const response = await axios.post(
            '/settings/change_password',
            { old_password: this.oldPassword, new_password: this.newPassword },
            { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }
        );
        alert(response.data.message);
        this.oldPassword = '';
        this.newPassword = '';
        this.confirmNewPassword = '';
      } catch (error) {
        alert(error.response?.data?.error || 'Failed to change password');
      }
    },
    async changeUsername() {
      try {
        const response = await axios.post(
            '/settings/change_username',
            { new_username: this.newUsername },
            { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }
        );
        localStorage.setItem('username', this.newUsername);
        if (response.data.token) {
          localStorage.setItem('access_token', response.data.token);
        }
        this.currentUsername = this.newUsername;
        eventBus.emit('username-changed', this.newUsername);
        alert(response.data.message);
        this.newUsername = '';
      } catch (error) {
        alert(error.response?.data?.error || 'Failed to change username');
      }
    },
    async changeEmail() {
      try {
        const response = await axios.post(
            '/settings/change_email',
            { new_email: this.newEmail },
            { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }
        );
        alert(response.data.message);
        this.currentEmail = this.newEmail;
        this.newEmail = '';
      } catch (error) {
        alert(error.response?.data?.error || 'Failed to change email');
      }
    },
    async updateDescription() {
      try {
        const response = await axios.post(
            '/settings/update_description',
            { description: this.description },
            { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }
        );
        alert(response.data.message);
        this.currentDescription = this.description;
        this.description = '';
      } catch (error) {
        console.error('Error updating description:', error);
        alert('Failed to update description');
      }
    },
    toggleTheme() {
      const newTheme = this.currentTheme === 'light' ? 'dark' : 'light';
      this.applyTheme(newTheme);
    },
    applyTheme(theme) {
      this.currentTheme = theme;
      this.themeButtonText = theme === 'light' ? 'Switch to Dark Mode' : 'Switch to Light Mode';
      const themeFile = theme === 'dark' ? '/styles/darktheme.css' : '/styles/lighttheme.css';
      let linkElement = document.getElementById('theme-link');
      if (!linkElement) {
        linkElement = document.createElement('link');
        linkElement.id = 'theme-link';
        linkElement.rel = 'stylesheet';
        document.head.appendChild(linkElement);
      }
      linkElement.href = themeFile;
      this.updateMetaTags(theme);
      localStorage.setItem('theme', theme);
    },
    updateMetaTags(theme) {
      const themeColor = theme === 'dark' ? '#03010a' : '#faf7fc';
      document.querySelector('meta[name="theme-color"]').setAttribute('content', themeColor);
      const statusBarStyle = theme === 'dark' ? 'black-translucent' : 'default';
      document.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]').setAttribute('content', statusBarStyle);
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    setViewportHeight() {
      this.vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${this.vh}px`);
    },
    handleResize() {
      this.checkMobile();
      this.setViewportHeight();
    },
  },
};
</script>

<style scoped>
.settingscontainer {
  max-width: 500px;
  margin-left: 40px;
  margin-right: auto;
  box-sizing: border-box;
  min-height: calc(var(--vh, 1vh) * 100 - 80px);
}

.settingswrapper {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: env(safe-area-inset-bottom);
}

.settingswrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.section {
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0px 0px 6px grey;
}

.profile-image-wrapper {
  text-align: center;
  margin-bottom: 15px;
}

.profile-image-preview {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
  color: rgba(131, 95, 139, 0.8);
}

input,
textarea {
  margin-bottom: 10px;
  width: 100%;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  border-radius: 10px;
  border: 2px solid rgba(131, 95, 139, 1);
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(131, 95, 139, 1);
  font-size: 0.9rem;
  box-sizing: border-box;
}

textarea {
  min-height: 80px;
  resize: vertical;
  box-sizing: border-box;
}

button {
  align-self: center;
  background-color: rgba(97, 61, 105, 0.5);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: rgba(97, 61, 105, 0.7);
}

h2,
h3 {
  color: rgba(131, 95, 139, 1);
}

@media screen and (max-width: 768px) {
  .settingscontainer {
    height: 100dvh;
    padding-bottom: 90px;
    -webkit-overflow-scrolling: touch;
    margin-left: auto;
  }

  .settingswrapper {
    padding-top: 10px;
  }

  .section {
    padding: 0.8rem;
  }

  h2 {
    font-size: 1.25rem;
  }

  h3 {
    font-size: 1rem;
  }

  input,
  textarea,
  button {
    font-size: 0.8rem;
    box-sizing: border-box;
  }
}
</style>

<template>
  <div class="consumer-feed" :style="{ minHeight: containerHeight }">
    <h2>Your personal Feed</h2>
    <div v-if="loading">Loading...</div>
    <div v-else-if="error">{{ error }}</div>

    <MediaItem
        v-for="media in feed"
        :key="media.id"
        :media="media"
        :creatorName="media.creator"
        :creator-profile-image="media.creatorProfileImage"
        :isCreator="false"
        @show-tip-modal="showTipModal"
    />
  </div>
  <div class="bottom"></div>
</template>

<script>
import axios from 'axios';
import MediaItem from './MediaItem.vue';

export default {
  components: {
    MediaItem,
  },
  data() {
    return {
      feed: [],
      loading: true,
      error: null,
      currentTipMedia: null,
      isMobile: false,
      vh: 0,
    };
  },
  computed: {
    containerHeight() {
      return `calc(${100 * this.vh}px - 80px)`;
    },
  },
  async created() {
    await this.fetchFeed();
    this.checkMobile();
    this.setViewportHeight();
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    async fetchFeed() {
      try {
        const response = await axios.get('/feed/consumer', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        });
        this.feed = response.data;
        this.loading = false;
      } catch (error) {
        this.error = 'Failed to load feed. Please try again later.';
        this.loading = false;
        console.error('Error fetching feed:', error);
      }
    },
    showTipModal(media) {
      this.currentTipMedia = media;
    },
    closeTipModal() {
      this.currentTipMedia = null;
    },
    async sendTip(amount) {
      if (!this.currentTipMedia) return;

      const accessToken = localStorage.getItem('access_token');
      try {
        await axios.post(`/media/${this.currentTipMedia.id}/tip`, {
          amount
        }, {
          headers: { Authorization: `Bearer ${accessToken}` }
        });
        // Update the UI to reflect the new tip
        this.currentTipMedia.total_tips += amount;
        this.closeTipModal();
      } catch (error) {
        console.error('Error sending tip:', error);
      }
    },

    creatorProfileImageUrl() {
      if (this.creator && this.creator.profile_image) {
        return this.creator.profile_image;
      }
      return require("@/assets/images/picreator.webp");
    },

    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    setViewportHeight() {
      this.vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${this.vh}px`);
    },
    handleResize() {
      this.checkMobile();
      this.setViewportHeight();
    },
  }
};
</script>

<style scoped>
.consumer-feed {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  box-sizing: border-box;
  min-height: calc(var(--vh, 1vh) * 100 - 80px);
}

h2 {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 25px;
  font-family: Roboto, sans-serif;
  color: var(--topbar-h1-color, #ffffff);

  font-weight: 100; /* Ensures the font is not bold */
}


.media-list {
  margin-right: 400px;
  min-width: 850px;
}

@media screen and (max-width: 768px) {
  .consumer-feed {
    min-width: 0;
    margin-right: 0;
    min-height: calc(var(--vh, 1vh) * 100 - 80px);
  }

  .media-list {
    width: 100%;
  }
  .bottom {
    padding-bottom: 90px;
  }
}
</style>

<template>
  <div class="navbar-phone" ref="navbarPhone">
    <div class="burger-menu" @click="toggleMenu">
      <span></span>
      <span></span>
      <span></span>
    </div>
    <nav class="mobile-nav" :class="{ 'nav-expanded': isMenuOpen }">
      <!-- Top container with close button -->
      <div class="top-container">
        <div class="close-container">
        <div class="close-button" @click="toggleMenu">✕</div>
        </div>
      </div>

      <!-- Profile Image centered below the close button -->
      <div class="profile-container">
        <ProfileImage :profileImageUrl="profileImageUrl" :userRole="userRole" />
      </div>

      <div class="username-container">
      <UserName :userName="userName" />
      </div>
      <ul>
        <li @click="navigateToHome">
          <img src="@/assets/images/home.png" alt="Home" class="menu-icon" /> Home
        </li>
        <li @click="navigateAndClose('/messages')">
          <img src="@/assets/images/message.png" alt="Messages" class="menu-icon" /> Messages
        </li>
        <li @click="navigateAndClose('/subscriptions')">
          <img src="@/assets/images/subscription.png" alt="Subscriptions" class="menu-icon" /> Subscriptions
        </li>
        <li @click="navigateAndClose('/settings')">
          <img src="@/assets/images/settings.png" alt="Settings" class="menu-icon" /> Settings
        </li>
        <li @click="logOffUser">
          <img src="@/assets/images/logout.webp" alt="Log Off" class="menu-icon" /> Log Off
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import axios from 'axios';
import ProfileImage from './ProfileImage.vue';
import UserName from './UserName.vue';

export default {
  name: "NavbarPhone",
  components: {
    ProfileImage,
    UserName
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  data() {
    return {
      isMenuOpen: false,
      profileImageUrl: '',
      userRole: 1, // Default to consumer role
      userName: ''
    };
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
    this.fetchUserProfile();
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    handleOutsideClick(event) {
      const navbarPhone = this.$refs.navbarPhone;
      if (navbarPhone && !navbarPhone.contains(event.target)) {
        this.isMenuOpen = false;
      }
    },
    navigateAndClose(path) {
      this.router.push(path);
      this.isMenuOpen = false;
    },
    async navigateToHome() {
      try {
        const accessToken = localStorage.getItem("access_token");
        if (accessToken) {
          const response = await axios.get("/users/profile", {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          const role = response.data.role;
          if (role === 2) {
            this.router.push({ path: `/${response.data.username}` });
          } else {
            this.router.push('/feed');
          }
        } else {
          this.router.push('/');
        }
        this.isMenuOpen = false;
      } catch (error) {
        console.error(error);
      }
    },
    logOffUser() {
      localStorage.removeItem('access_token');
      localStorage.removeItem('user_id');
      localStorage.removeItem('username');
      localStorage.removeItem('role');
      this.router.push('/');
      this.isMenuOpen = false;
    },
    async fetchUserProfile() {
      try {
        const accessToken = localStorage.getItem("access_token");
        if (accessToken) {
          const response = await axios.get("/users/profile", {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          // Append the access token to the profile image URL as a query string
          this.profileImageUrl = `${response.data.profile_image}?token=${accessToken}`;
          this.userRole = response.data.role;
          this.userName = response.data.username;
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    }
  }
};
</script>

<style scoped>
.navbar-phone {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  height: 50px;
}

.burger-menu {
  cursor: pointer;
  padding: 10px;
  position: fixed;
  width: 30px;
  height: 30px;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--burger-menu-bg);
  border-radius: 5px;
  z-index: 1001; /* Ensure it's above the mobile nav */
}

.burger-menu span {
  display: block;
  height: 3px;
  width: 100%;
  background-color: var(--burger-menu-color);
  transition: all 0.3s ease;
  border-radius: 5rem;
}

.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 0; /* Start with 0 width */
  height: 100vh;
  background-color: var(--navbar-background);
  overflow: hidden; /* Hide content when closed */
  transition: width 0.3s ease-in-out;
  z-index: 1000;
}

.nav-expanded {
  width: 70%; /* Expand to 70% when open */
  z-index: 100000;
}

/* Profile, close button, and username - transition with delay */
.profile-container,
.username-container,
.close-container {
  opacity: 0;
  transition: opacity 0.3s ease-in-out 0.3s; /* Start after 0.3s */
}

.nav-expanded .profile-container,
.nav-expanded .username-container,
.nav-expanded .close-container {
  opacity: 1;
  transition: opacity 0.3s ease-in-out 0.3s; /* End at 0.6s */
}

/* Top container with the close button aligned to the right */
.top-container {
  position: relative;
  padding: 10px;
}

.close-container {
  background: #d9ccdb;
  width: 35px;
  height: 35px;
  position: absolute;
  right: 20px;
  top: 20px;
  border-radius: 100px;

  /* Flexbox properties for centering */
  display: flex;
  justify-content: center; /* Horizontal centering */
  align-items: center; /* Vertical centering */
  transition: opacity 0.1s ease-in-out, transform 0.01s ease-in-out; /* Apply transitions */

}

.close-button {
  font-size: 24px;
  cursor: pointer;
  color: var(--topbar-h1-color);
  transition: opacity 0.1s ease-in-out, transform 0.01s ease-in-out; /* Apply transitions */

}

/* Profile image container centered below the close button */
.profile-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  transition: opacity 0.1s ease-in-out, transform 0.01s ease-in-out; /* Apply transitions */
  transform: translateX(-10px); /* Slight offset for slide-in effect */
}

.username-container {
  padding-bottom: 20px;
  transition: opacity 0.1s ease-in-out, transform 0.01s ease-in-out; /* Apply transitions */
  transform: translateX(-10px); /* Slight offset for slide-in effect */
}

/* Transition only on the text (ul li) */
.mobile-nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
  width: 80vw; /* Set the width to 80% of viewport width */
  opacity: 0; /* Hidden by default */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; /* Apply transitions */
  transform: translateX(-10px); /* Slight offset for slide-in effect */
}

.nav-expanded ul {
  opacity: 1; /* Show when expanded */
  transform: translateX(0); /* Reset position */
}

.menu-icon {
  width: 25px;
  height: 25px;
  margin-right: 15px;
  filter: var(--menu-icon-filter);
}

.mobile-nav ul li {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  display: flex;
  padding: 20px;

  cursor: pointer;
  color: var(--topbar-h1-color);
  white-space: nowrap; /* Prevent text wrapping */
}

.mobile-nav ul li img {
  margin-right: 30px;
  margin-left: 10px;
  display: inline-block;
  flex-shrink: 0; /* Prevent the icon from shrinking */
}

.mobile-nav ul li:hover {
  background-color: rgba(97, 61, 105, 0.15);
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .burger-menu {
    display: flex;
  }
}


</style>

<template>
  <div class="left-container">
    <div class="content-wrapper">
      <AppLogo />
      <div class="navigation-container">
        <ProfileImage :profileImageUrl="profileImageUrl" :userRole="userProfile?.role" />
        <UserName :userName="userName" />
        <MenuBar :userProfile="userProfile" />
      </div>
    </div>
  </div>
</template>

<script>
import AppLogo from '@/components/containers/AppLogo.vue';
import ProfileImage from '@/components/containers/ProfileImage.vue';
import UserName from '@/components/containers/UserName.vue';
import MenuBar from '@/components/containers/MenuBar.vue';
import axios from 'axios';
import eventBus from '../utils/eventBus';

export default {
  name: 'LeftBar',
  components: {
    AppLogo,
    ProfileImage,
    UserName,
    MenuBar
  },

  data() {
    return {
      userName: '',
      profileImageUrl: '',
      userProfile: null, // Contains role, username, etc.
    };
  },
  methods: {
    loadUserName() {
      const name = localStorage.getItem("name");
      if (name) {
        this.userName = name;
      }
    },
    async loadUserProfile() {
      try {
        const response = await axios.get('/users/profile', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        });
        const accessToken = localStorage.getItem('access_token');

        // Check if the profile image is null and handle accordingly
        if (response.data.profile_image) {
          this.profileImageUrl = `${response.data.profile_image}?token=${accessToken}`;
        } else {
          // If profile image is null, set profileImageUrl to null or a default image
          this.profileImageUrl = null;
        }

        this.userName = response.data.username;
        this.userProfile = response.data;
      } catch (error) {
        console.error('Error loading profile:', error);
      }
    },
    updateProfileImage(newUrl) {
      this.profileImageUrl = newUrl;
    }
  },
  mounted() {
    this.loadUserName();
    this.loadUserProfile();

    // Listen for profile image update event
    eventBus.on('profile-image-updated', this.updateProfileImage);
  },
  beforeUnmount() {
    // Remove event listener
    eventBus.off('profile-image-updated', this.updateProfileImage);
  }
};
</script>

<style scoped>
.left-container {
  min-width: 300px;
  height: 100vh;
  margin-left: 0;
}

.navigation-container {
  height: 500px;
  min-width: 150px;
  width: 180px;
  margin-left: auto;
  margin-right: 0px;
  margin-top: 65px;
  border-radius: 10px;
  border: 1px solid rgba(175, 139, 182, 0.5);
  box-shadow: var(--navigation-container-box-shadow);
  background-color: var(--navigation-container-background);
}

.content-wrapper {
  width: 180px;
  position: absolute;
  left: 10.6%;
}

@media screen and (max-width: 768px) {
  .left-container {
    width: 0%;
    min-width: 0px;
    height: 0vh;
    margin-left: 0;
    display: none;
  }

  .navigation-container {
    height: 500px;
    min-width: 150px;
    width: 180px;
    margin-left: auto;
    margin-right: 0px;
    margin-top: 65px;
    border-radius: 10px;
    border: 1px solid rgba(175, 139, 182, 0.5);
    box-shadow: var(--navigation-container-box-shadow);
    background-color: var(--navigation-container-background);
  }

  .content-wrapper {
    width: 0px;
    position: absolute;
    left: 10.6%;
    content-visibility: hidden;
  }
}
</style>

<template>
  <div class="backgroundfeed" :style="{ minHeight: containerHeight }">
    <div v-if="creator && !loading">
      <ProfileHeader
          :creator="creator"
          :bannerUrl="creatorBannerImageUrl"
          :profileImageUrl="creatorProfileImageUrl"
          :description="creator.description"
          :isLoading="isLoading"
          :isCreator="isCreator"
          :isSubscribed="isSubscribed"
          @toggle-subscription="toggleSubscription"
      />
      <button v-if="isCreator" @click="openUploadModal" class="upload-button">
        Upload New Media
      </button>
      <UploadMedia
          :isOpen="isUploadModalOpen"
          @close="closeUploadModal"
          @upload-success="handleUploadSuccess"
      />
      <MediaItem
          v-for="media in mediaList"
          :key="media.id"
          :media="media"
          :creator-name="creator.username"
          :creator-profile-image="media.creatorProfileImage"
      :isCreator="isCreator"
      @media-deleted="removeMedia(media.id)"
      />

    </div>
    <div v-else-if="loading">
      <h2>Loading...</h2>
    </div>
    <div v-else>
      <h2>Error</h2>
      <p v-if="error.message">{{ error.message }}</p>
      <p v-else>An error occurred. Please try again later.</p>
    </div>
  </div>
  <div class="bottom"></div>
</template>

<script>
import axios from "axios";
import MediaItem from "./MediaItem.vue";
import ProfileHeader from "./ProfileHeader.vue";
import UploadMedia from "./UploadMedia.vue";

export default {
  components: {
    MediaItem,
    ProfileHeader,
    UploadMedia,
  },
  data() {
    return {
      creator: null,
      mediaList: [],
      loading: true,
      error: null,
      isCreator: false,
      isSubscribed: false,
      isLoading: true,
      isUploadModalOpen: false,
      isMobile: false,
      vh: 0,
      accessToken: localStorage.getItem("access_token"),
    };
  },
  computed: {
    currentUserId() {
      return localStorage.getItem("user_id");
    },
    containerHeight() {
      return `calc(${100 * this.vh}px - 80px)`;
    },
    creatorProfileImageUrl() {
      if (this.creator && this.creator.profile_image) {
        return this.creator.profile_image;
      }
      return require("@/assets/images/picreator.webp");
    },
    creatorBannerImageUrl() {
      if (this.creator && this.creator.banner_image) {
        return this.creator.banner_image;
      }
      return require("@/assets/images/defaultbanner.webp");
    },
  },
  methods: {
    async removeMedia(mediaId) {
      console.log("Deleting media with ID:", mediaId);
      try {
        if (!this.accessToken) {
          console.error("User not authenticated");
          return;
        }
        const response = await axios.delete(`/media/${mediaId}`, {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        });
        if (response.status === 200) {
          this.mediaList = this.mediaList.filter((media) => media.id !== mediaId);
        } else {
          console.error("Failed to delete media:", response.data.error || "Unknown error");
          alert("Failed to delete media. " + (response.data.error || "Unknown error"));
        }
      } catch (error) {
        console.error("Error deleting media:", error);
        alert("An error occurred while deleting media.");
      }
    },
    async toggleSubscription() {
      try {
        const url = this.isSubscribed ? "/subscriptions" : "/subscriptions";
        const method = this.isSubscribed ? "delete" : "post";
        const response = await axios({
          method: method,
          url: `${process.env.VUE_APP_BASE_URL}${url}`,
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
            "Content-Type": "application/json",
          },
          data: {
            creator_id: this.creator.id,
          },
        });
        if (response.status === 200 || response.status === 201) {
          this.isSubscribed = !this.isSubscribed;
          await this.fetchMediaList();
        } else {
          console.error(`Failed to toggle subscription: ${response.data.error || "Unknown error"}`);
          alert(`Failed to toggle subscription: ${response.data.error || "Unknown error"}`);
        }
      } catch (error) {
        console.error("Error toggling subscription:", error);
        alert("An error occurred while toggling subscription.");
      }
    },
    async fetchMediaList() {
      const creatorId = this.$route.params.id;
      try {
        const headers = this.accessToken ? { Authorization: `Bearer ${this.accessToken}` } : {};
        const feedResponse = await axios.get(`/feed/creator/${creatorId}/feed`, {
          headers: headers,
        });
        this.mediaList = feedResponse.data;
        this.isSubscribed = true;
      } catch (error) {
        console.error("Error fetching media list:", error);
        if (error.response && error.response.status === 401) {
          this.isSubscribed = false;
          this.mediaList = [];
        } else {
          this.error = error;
        }
      }
    },
    openUploadModal() {
      this.isUploadModalOpen = true;
    },
    closeUploadModal() {
      this.isUploadModalOpen = false;
    },
    async handleUploadSuccess() {
      this.isUploadModalOpen = false;
      await this.fetchMediaList();
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    setViewportHeight() {
      this.vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${this.vh}px`);
    },
    handleResize() {
      this.checkMobile();
      this.setViewportHeight();
    },
  },
  async created() {
    const creatorId = this.$route.params.id;

    try {
      if (!creatorId) {
        throw new Error("Creator ID is missing.");
      }
      if (!this.accessToken) {
        this.$router.push("/login");
        return;
      }
      this.loading = true;
      const response = await axios.get(`/creator/${creatorId}`, {
        headers: { Authorization: `Bearer ${this.accessToken}` },
      });
      this.creator = response.data;
      this.isCreator = this.currentUserId === String(this.creator.id);
      await this.fetchMediaList();
      if (!this.isCreator) {
        const subResponse = await axios.get(`/subscriptions/check/${creatorId}`, {
          headers: { Authorization: `Bearer ${this.accessToken}` },
        });
        this.isSubscribed = subResponse.data.is_subscribed;
      }
    } catch (error) {
      console.error("Error fetching creator details:", error);
      this.error = error;
    } finally {
      this.loading = false;
    }

    this.checkMobile();
    this.setViewportHeight();
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    "$route.params.id": async function (newId, oldId) {
      if (newId !== oldId) {
        this.creator = null;
        this.mediaList = [];
        this.isSubscribed = false;
        this.loading = true;
        try {
          const response = await axios.get(`/creator/${newId}`, {
            headers: { Authorization: `Bearer ${this.accessToken}` },
          });
          this.creator = response.data;
          this.isCreator = this.currentUserId === String(this.creator.id);
          await this.fetchMediaList();
          if (!this.isCreator) {
            const subResponse = await axios.get(`/subscriptions/check/${newId}`, {
              headers: { Authorization: `Bearer ${this.accessToken}` },
            });
            this.isSubscribed = subResponse.data.is_subscribed;
          }
        } catch (error) {
          console.error("Error fetching creator details:", error);
          this.error = error;
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>



<style scoped>
.backgroundfeed {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;

  box-sizing: border-box;
  min-height: calc(var(--vh, 1vh) * 100 - 80px);
}

.subscribed {
  background-color: rgba(142, 100, 149, 1);
}

.banner-image img {
  width: 550px;
  border-start-end-radius: 40px;
  border-end-start-radius: 40px;
  border-style: solid;
  border-color: rgba(131, 95, 139, 1);
  border-width: 1px;
  top: -6px;
}

.profile-info img {
  width: 100px;
  position: relative;
  top: -30px;
  right: 175px;
  border-style: solid;
  border-color: rgba(131, 95, 139, 1);
  border-width: 1px;
  border-radius: 50%;
  box-shadow: 0px 4px 4px 3px rgba(0, 0, 0, 0.25);
}

.profile-info h2 {
  position: relative;
  top: -120px;
  right: 90px;
  color: white;
  font-family: 'Rouge Script', cursive;
  font-weight: 200;
}

.actions button {
  height: 35px;
  border-radius: 30px;
  width: 100%;
  position: relative;
}

.profile-info p {
  top: -90px;
  position: relative;
  color: white;
  font-family: 'Rouge Script', cursive;
}

.upload-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  border-radius: 50px;
  background-color: rgba(97, 61, 105, 0.5);
  border: none;
  color: white;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  transition: all 0.3s;
}

.upload-button:hover {
  background-color: rgba(97, 61, 105, 0.8);
}

.uploadform {
  position: relative;
  margin-bottom: 40px;
  top: -90px;
  border-radius: 10px;
  background-color: red;
}

.subscribebtn {
  position: relative;
  height: 30px;
  width: 120px;
  margin-bottom: 40px;
  top: -90px;
  border-radius: 10px;
  border-color: rgba(131, 95, 139, 1);
  border-width: 2px;
  border-radius: 10px;
  background-color: rgba(131, 95, 139, 0);
}

.description p {
  height: 10px;
  top: -90px;
  position: relative;
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .backgroundfeed {
    min-width: 0;
    margin-right: 0;
    min-height: calc(var(--vh, 1vh) * 100 - 80px);
  }

  .banner-image img {
    width: 100%;
    max-width: 100%;
    border-radius: 20px;
  }

  .profile-info img {
    position: static;
    display: block;
    margin: -50px auto 0;
  }

  .profile-info h2 {
    position: static;
    text-align: center;
    margin-top: 10px;
  }

  .profile-info p {
    position: static;
    text-align: center;
  }

  .actions button,
  .upload-button,
  .subscribebtn {
    width: 100%;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .description p {
    position: static;
    margin-bottom: 20px;
  }

  .bottom {
    padding-bottom: 90px;
  }
}
</style>
// src/eventBus.js
import { ref } from 'vue';

const eventBus = {
  events: ref({}), // Use an object to store events by name

  on(event, callback) {
    if (!this.events.value[event]) {
      this.events.value[event] = []; // Initialize the event list if it doesn't exist
    }
    this.events.value[event].push(callback);
  },

  off(event, callback) {
    if (!this.events.value[event]) return;

    this.events.value[event] = this.events.value[event].filter(cb => cb !== callback);

    // Remove the event key if no listeners remain
    if (this.events.value[event].length === 0) {
      delete this.events.value[event];
    }
  },

  emit(event, data) {
    if (!this.events.value[event]) return;

    this.events.value[event].forEach(callback => {
      callback(data);
    });
  }
};

export default eventBus;

<template>
  <div class="media-item">
    <div class="media-header">
      <div class="creator-info">
        <div class="creator-image">
          <img :src="getProfileImage(creatorProfileImage)" alt="Profile" class="profile-image" />
        </div>
        <div class="creator-name">
          <router-link :to="{ name: 'creatorFeed', params: { id: creatorName }}" class="creator-name">
            {{ creatorName }}
          </router-link>
        </div>
      </div>
      <div class="date">
        <p>{{ formatDate(media.created_at) }}</p>
      </div>
      <div class="dropdown">
        <button @click="toggleDropdown" class="three-dots-btn">...</button>
        <div v-if="showDropdown" class="dropdown-menu">
          <button v-if="isCreator" @click="deleteMedia(media.id)">Delete</button>
        </div>
      </div>
    </div>
    <div class="media-description">
      <p>{{ media.description }}</p>
    </div>

    <div class="media-content">
      <button @click="previousMedia" class="nav-button left" v-if="media.file_paths.length > 1">&lt;</button>
      <div v-if="currentMediaType === 'image'">
        <img
            :src="getMediaUrl(media.file_paths[currentMediaIndex])"
            alt="Media"
            class="media-image"
            @click="openFullScreen"
            @contextmenu.prevent
            @mousedown.prevent
            @load="handleImageLoad"
        />
      </div>
      <div v-else-if="currentMediaType === 'video'">
        <video controls controlslist="nodownload loop noplaybackrate" class="media-video">
          <source :src="getMediaUrl(media.file_paths[currentMediaIndex])" :type="getVideoMimeType(media.file_paths[currentMediaIndex])" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div class="audioplayer" v-else-if="currentMediaType === 'audio'">
        <CustomAudioPlayer :audioSrc="getMediaUrl(media.file_paths[currentMediaIndex])" :audioTitle="media.description" />
      </div>
      <button @click="nextMedia" class="nav-button right" v-if="media.file_paths.length > 1">&gt;</button>
    </div>

    <div class="media-details">
      <div class="media-bottom">
        <div class="data-row">
          <button @click="toggleLike" class="icon-button like-button">
            <img :src="isLiked ? likedIcon : likeIcon" alt="Like Icon" />
          </button>
          <span>{{ likesCount }}</span>
        </div>
        <div class="data-row">
          <button @click="toggleComments" class="icon-button comment-button">
            <img :src="isComment ? commentedIcon : commentIcon" alt="Comment Icon" />
          </button>
          <span>{{ commentsCount }}</span>
        </div>
        <div class="data-row">
          <button @click="showTipModale = true" class="icon-button tribute-button">
            <img src="../assets/images/prey_image.png" alt="Tribute" />
          </button>
          <span>${{ displayTotalTips }}</span>
        </div>
      </div>
      <div v-if="showComments" class="comments-section">
        <div class="add-comment">
          <textarea v-model="newComment" placeholder="Surrender a comment..."></textarea>
          <button @click="addComment">Post Comment</button>
        </div>
        <div v-if="comments.length === 0">
          <p>No comments yet.</p>
        </div>
        <div v-else>
          <CommentSection v-for="comment in comments" :key="comment.id" :comment="comment" />
        </div>
      </div>
    </div>

    <div v-if="isFullScreen" class="fullscreen-overlay">
      <button @click="previousMedia" class="nav-button left" v-if="media.file_paths.length > 1">←</button>
      <div class="image-container">
        <img
            :src="getMediaUrl(media.file_paths[currentMediaIndex])"
            class="fullscreen-image"
            @contextmenu.prevent
            alt="Fullscreen media"
        />
        <button class="close-btn" @click="closeFullScreen">✕</button>
      </div>
      <button @click="nextMedia" class="nav-button right" v-if="media.file_paths.length > 1">→</button>
    </div>

    <TipModale
        v-if="showTipModale"
        @close="showTipModale = false"
        @confirm="sendTip"
        @update-total-tips="updateTotalTips"
        :creatorName="creatorName"
        :creatorProfileImage="getProfileImage(creatorProfileImage)"
        :mediaId="media.id"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import CommentSection from './CommentSection.vue';
import CustomAudioPlayer from './CustomAudioPlayer.vue';
import likeIcon from '@/assets/images/Group.svg';
import likedIcon from '@/assets/images/161956 2.svg';
import TipModale from './TipModale.vue';
import commentIcon from '@/assets/images/commentIcon.svg';
import commentedIcon from '@/assets/images/comment-59.svg';
import eventBus from '@/utils/eventBus';

export default defineComponent({
  name: 'MediaItem',
  components: {
    CommentSection,
    TipModale,
    CustomAudioPlayer,
  },
  props: {
    media: Object,
    creatorProfileImage: String,
    creatorName: String,
    isCreator: Boolean,
    initialIsSubscribed: Boolean,
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  data() {
    return {
      showDropdown: false,
      likesCount: 0,
      isLiked: false,
      isMuted: false,
      volume: 50,
      likeIcon: likeIcon,
      likedIcon: likedIcon,
      commentIcon: commentIcon,
      commentedIcon: commentedIcon,
      comments: [],

      newComment: '',
      showComments: false,
      commentsCount: 0,
      localTotalTips: this.media.total_tips,
      showTipModale: false,
      isFullScreen: false,
      currentMediaIndex: 0,
      localIsSubscribed: this.initialIsSubscribed,
    };
  },
  computed: {
    displayTotalTips() {
      return this.localTotalTips;
    },
    currentMediaType() {
      const currentFile = this.media.file_paths[this.currentMediaIndex];
      if (this.isImage(currentFile)) return 'image';
      if (this.isVideo(currentFile)) return 'video';
      if (this.isAudio(currentFile)) return 'audio';
      return 'unknown';
    },
  },
  watch: {
    initialIsSubscribed(newValue) {
      this.localIsSubscribed = newValue;
    },
  },
  methods: {
    getProfileImage(profileImage) {
      const accessToken = localStorage.getItem('access_token');
      if (!profileImage) {
        return require('@/assets/images/360_F_549983970_bRCkYfk0P6PP5fKbMhZMIb07mCJ6esXL.jpg'); // Path to your default image
      }
      return `${axios.defaults.baseURL}${profileImage}?token=${accessToken}`;
    },

    navigateToCreatorFeed(creatorName) {
      this.router.push({ name: 'creatorFeed', params: { id: creatorName } });
    },
    previousMedia() {
      if (this.currentMediaIndex > 0) {
        this.currentMediaIndex--;
      } else {
        this.currentMediaIndex = this.media.file_paths.length - 1;
      }
    },
    nextMedia() {
      if (this.currentMediaIndex < this.media.file_paths.length - 1) {
        this.currentMediaIndex++;
      } else {
        this.currentMediaIndex = 0;
      }
    },
    openFullScreen() {
      this.isFullScreen = true;
    },
    handleImageLoad(event) {
      const img = event.target;
      img.setAttribute('draggable', 'false');
      img.setAttribute('oncontextmenu', 'return false;');
    },
    handleContextMenu(event) {
      event.preventDefault();
    },
    closeFullScreen() {
      this.isFullScreen = false;
    },
    async sendTip(tipData) {
      const accessToken = localStorage.getItem('access_token');
      try {
        const response = await axios.post(
            `/media/${this.media.id}/tip`,
            { amount: tipData.amount },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
        );
        this.localTotalTips = response.data.total_tips;
        this.showTipModale = false;
      } catch (error) {
        console.error('Error sending tip:', error);
      }
    },
    isImage(filePath) {
      return /\.(jpg|jpeg|png|webp)$/i.test(filePath);
    },
    isVideo(filePath) {
      return /\.(mp4|webm|ogg|mov)$/i.test(filePath);
    },
    isAudio(filePath) {
      return /\.(m4a|mp3|wav|ogg|opus|weba)$/i.test(filePath);
    },
    formatDate(dateString) {
      if (!dateString) return 'Date unknown';
      try {
        const cleanDateString = dateString.split('.')[0];
        const date = new Date(cleanDateString);
        if (isNaN(date.getTime())) {
          return 'Invalid date';
        }
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleString(undefined, options);
      } catch (error) {
        console.error('Error formatting date:', error);
        return 'Date format error';
      }
    },
    getMediaUrl(filePath) {
      const accessToken = localStorage.getItem('access_token');
      if (this.isVideo(filePath) || this.isAudio(filePath)) {
        return `${axios.defaults.baseURL}/stream/${filePath}?token=${accessToken}`;
      } else if (this.isImage(filePath)) {
        return `${axios.defaults.baseURL}/uploads/MediaContent/${filePath}?token=${accessToken}`;
      }
    },
    getVideoMimeType(filePath) {
      const extension = filePath.split('.').pop().toLowerCase();
      const mimeTypes = {
        mp4: 'video/mp4',
        webm: 'video/webm',
        ogg: 'video/ogg',
        mov: 'video/quicktime',
      };
      return mimeTypes[extension] || 'video/mp4';
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    async deleteMedia(mediaId) {
      try {
        const accessToken = localStorage.getItem('access_token');
        await axios.delete(`/media/${mediaId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        this.$emit('media-deleted', mediaId);
      } catch (error) {
        console.error('Error deleting media:', error);
      }
    },
    async fetchLikes() {
      const accessToken = localStorage.getItem('access_token');
      try {
        const response = await axios.get(`/media/${this.media.id}/likes`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        this.likesCount = response.data.likes_count;
        this.checkIfLiked();
      } catch (error) {
        console.error('Error fetching likes:', error);
      }
    },
    async checkIfLiked() {
      const accessToken = localStorage.getItem('access_token');
      try {
        const response = await axios.get(`/media/${this.media.id}/is_liked`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        this.isLiked = response.data.is_liked;
      } catch (error) {
        console.error('Error checking like status:', error);
      }
    },
    async toggleLike() {
      const accessToken = localStorage.getItem('access_token');
      try {
        if (this.isLiked) {
          await axios.post(`/media/${this.media.id}/unlike`, {}, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          this.isLiked = false;
          this.likesCount--;
        } else {
          await axios.post(`/media/${this.media.id}/like`, {}, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          this.isLiked = true;
          this.likesCount++;
        }
      } catch (error) {
        console.error('Error toggling like:', error);
      }
    },
    async fetchComments() {
      const accessToken = localStorage.getItem('access_token');
      try {
        const response = await axios.get(`/media/${this.media.id}/comments`, {}, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        this.comments = response.data;
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    },
    async fetchCommentsCount() {
      try {
        const response = await axios.get(`/media/${this.media.id}/comments/count`);
        return response.data.count;
      } catch (error) {
        console.error('Error fetching comments count:', error);
        return 0;
      }
    },
    updateTotalTips(newTotalTips) {
      this.localTotalTips = newTotalTips;
    },
    async addComment() {
      if (!this.newComment.trim()) return;

      const accessToken = localStorage.getItem('access_token');
      try {
        const response = await axios.post(
            `/media/${this.media.id}/comments`,
            {
              content: this.newComment,
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
        );

        const newComment = {
          id: response.data.id,
          userId: response.data.userId,
          mediaId: response.data.mediaId,
          content: response.data.content,
          created_at: response.data.createdAt,
          username: response.data.username,
          profileImage: response.data.profileImage,
        };

        this.comments.push(newComment);
        this.newComment = '';
        this.commentsCount++;
      } catch (error) {
        console.error('Error adding comment:', error);
        alert('Failed to add comment. Please try again.');
      }
    },
    async toggleComments() {
      this.showComments = !this.showComments;
      if (this.showComments && this.comments.length === 0) {
        await this.fetchComments();
      }
    },
    async checkSubscriptionStatus() {
      try {
        const response = await axios.get(`/subscriptions/check/${this.creatorName}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
        });
        this.localIsSubscribed = response.data.is_subscribed;
      } catch (error) {
        console.error('Error checking subscription status:', error);
      }
    },
    handleSubscriptionChange({ creatorId, isSubscribed }) {
      if (creatorId === this.media.creator_id) {
        this.localIsSubscribed = isSubscribed;
      }
    },
  },
  async created() {
    await this.fetchLikes();
    this.commentsCount = await this.fetchCommentsCount();
  },

  mounted() {
    this.checkSubscriptionStatus();
    eventBus.on('subscription-changed', this.handleSubscriptionChange);
  },
  beforeUnmount() {
    eventBus.off('subscription-changed', this.handleSubscriptionChange);
  },
});
</script>

<style scoped>
/* Your existing CSS */
.media-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(255,255,255,0.1);
  max-width: calc(550px - 20px);
  min-height: 100%;
  margin: 30px auto 20px;
  border-radius: 15px;
  padding: 10px;
  box-shadow: 0px 4px 50px 6px rgba(0, 0, 0, 0.25);
  padding-bottom: env(safe-area-inset-bottom);
}

.media-header {
  display: flex;
  justify-content: space-between;
}

.media-bottom {
  display: flex;
  justify-content: flex-start;
  margin-top: auto; /* Pushes this element to the bottom */
  padding-bottom: 10px;
  margin-left: 30px;
}

.data-row {
  display: flex;
  align-items: center;
  margin-right: 50px;
  font-size: 0.9rem;
}

.icon-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.media-image, .fullscreen-image {
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.icon-button img {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.like-button, .comment-button, .tribute-button {
  filter: brightness(0) saturate(100%) invert(45%) sepia(7%) saturate(2178%) hue-rotate(244deg) brightness(96%) contrast(95%);
}

.date {
  margin-left: auto;
  margin-right: 10px;
  font-size: 0.8rem;
  padding-top: 8px;
  color: var(--creator-name-color, white);
}

.creator-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.creator-image {
  width: 40px;
  height: 40px;
  margin-left: 10px;
  margin-top: 10px;
  margin-right: 10px;
}

.profile-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #ddd;
  object-fit: cover;
}

.creator-name {
  font-size: 22px;
  color: var(--creator-name-color, white);
  font-family: 'Rouge Script', cursive;
  text-decoration: none;
  cursor: pointer;
}

.creator-name:hover {
  text-decoration: underline;
  text-decoration-thickness: 1px;
}

.media-description {
  margin-bottom: 10px;
  font-style: italic;
  color: #555;
}

.media-content {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%; /* Adjust this value based on your desired minimum height */
}

.media-image, .media-video {
  max-width: 100%;
  max-height: 400px;
  object-fit: contain;
  margin: auto;
  border-radius: 15px;
  cursor: pointer;
  min-width: 100%;
  padding-bottom: 10px;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  border: none;
  height: 100px;
  width: 50px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 18px;
  border-radius: 5px;
  transition: background-color 0.3s;
  z-index: 1;
}

.nav-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.nav-button.left {
  left: 0px;
}

.nav-button.right {
  right: 0px;
}

.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5000;
}

.image-container {
  position: relative;
  max-width: 100%;
  max-height: 100%;
}

.fullscreen-image {
  width: 100%;
  height: 100dvh;
  object-fit: contain;
  border-radius: 10px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  transition: background 0.3s;
  width: 60px;
  height: 60px;
}

.close-btn:hover {
  background: rgba(0, 0, 0, 0.8);
}

.media-details {
  margin-top: 0px;
}
.audioplayer{
  width: 100%;


}
.three-dots-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: white;
  padding-top: 10px;
}

.dropdown-menu {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  right: 10px;
  top: 40px;
}

.dropdown-menu button {
  background: none;
  border: none;
  padding: 10px;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.liked {
  filter: invert(39%) sepia(95%) saturate(7340%) hue-rotate(314deg) brightness(95%) contrast(117%);
}

.add-comment {
  margin-top: 8px;
}

.add-comment textarea {
  resize: none;
  width: 100%;
  height: 60px;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 1px solid #ddd;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
}

.add-comment button {
  align-self: flex-end;
  height: 40px;
  border: none;
  background: rgba(142, 100, 149, 1);
  color: white;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.comment {
  text-align: left;
}

@media screen and (max-width: 768px){
  .media-item {
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: rgba(255,255,255,0.1);
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px;
    padding: 0px;
    padding-bottom: env(safe-area-inset-bottom);
  }

  .media-header {
    display: flex;
    align-items: center;
  }

  .creator-info {
    display: flex;
    align-items: center;
  }

  .profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    border: 1px solid #ddd;
    margin-left: 10%;
    margin-top: 10%;
    object-fit: cover;
  }

  .creator-name {
    font-size: 22px;
    color: var(--creator-name-color, white);
    font-family: 'Rouge Script', cursive;
  }

  .media-description {
    font-style: italic;
    color: #555;
  }

  .media-image, .media-video {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0px;
    border-radius: 20px;
  }

  .media-details {
    margin-top: 0px;
  }

  .three-dots-btn {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: white;
  }

  .dropdown-menu {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    right: 10px;
    top: 40px;
  }

  .dropdown-menu button {
    background: none;
    border: none;
    padding: 10px;
    width: 100%;
    text-align: left;
    cursor: pointer;
  }

  .liked {
    filter: invert(39%) sepia(95%) saturate(7340%) hue-rotate(314deg) brightness(95%) contrast(117%);
  }

  .comments-section {
    margin-top: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
  }

  .add-comment {
    margin-top: 8px;
  }

  .add-comment textarea {
    resize: none;
    width: 100%;
    height: 60px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: 1px solid #ddd;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
  }

  .add-comment button {
    align-self: flex-end;
    height: 40px;
    border: none;
    background: rgba(142, 100, 149, 1);
    color: white;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }

  .comment {
    text-align: left;
  }

  .media-bottom {
    /* Add any specific styles for media-bottom on mobile if needed */
  }

  .nav-button {
    padding: 5px 10px;
    font-size: 16px;
  }
}


@media (max-width: 768px) and (orientation: portrait) {
  @supports (padding-bottom: env(safe-area-inset-bottom)) {
    .media-item {
      padding-bottom: env(safe-area-inset-bottom);
    }
  }
}
</style>

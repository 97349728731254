<template lang="en">
  <div class="profile-header-wrapper">
    <div class="profile-header">
      <div class="banner-image-container" :style="bannerStyle">
        <img :src="bannerImage" alt="Banner" />
      </div>
      <div class="profile-info">
        <div class="profile-image-wrapper" :style="profileImageStyle">
          <img :src="profileImage" alt="Profile" class="profile-image" />
        </div>
        <div class="buttons-container">
          <button
            class="round-button message-button"
            @click="handleMessageButtonClick"
            :title="messageButtonTitle"
          >
            <img src="@/assets/images/SendMessage.svg" alt="Message" />
          </button>
          <button class="round-button tribute-button" @click="showTipModal = true">
            <img src="@/assets/images/Credit.svg" alt="Tribute" />
          </button>
        </div>
      </div>
      <h2 class="creator-name">{{ creator.username }}</h2>
      <TipModal
        v-if="showTipModal"
        @close="showTipModal = false"
        @payment-success="handlePaymentSuccess"
        :creatorName="creator.username"
        :creatorProfileImage="profileImage"
        :creatorId="creator.id"
      />
      <div class="description-container">
        <DescriptionHeader
          :description="descriptionText"
          :isLoading="isLoading"
          @toggle-subscription="toggleSubscriptionModal"
        />
        <div class="description" v-if="creator.role === 2">
          <p v-html="isExpanded ? formattedDescription : shortDescription"></p>
          <button @click="toggleDescription" class="toggle-button">
            {{ isExpanded ? 'Read Less' : 'Read More' }}
          </button>
          <button
            class="subscribebtn"
            v-if="!isCreator"
            @click="toggleSubscriptionModal"
            :class="{ subscribed: isSubscribed }"
          >
            {{ isSubscribed ? `Subscribed: ${subscriptionPriceText}` : `Subscribe: ${subscriptionPriceText}` }}
          </button>
        </div>
      </div>

      <SubscriptionModal
        v-if="showSubscriptionModal"
        :creatorUsername="creator.username"
        :creatorBanner="bannerImage"
        :creatorProfileImage="profileImage"
        :subscriptionPrice="subscriptionPriceText"
        :isSubscribed="isSubscribed"
        :creatorId="creator.id"
        @close="showSubscriptionModal = false"
        @subscribe="handleSubscribe"
        @unsubscribe="handleUnsubscribe"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import DescriptionHeader from "./DescriptionHeader.vue";
import TipModal from "./TipModal.vue";
import SubscriptionModal from "./SubscriptionModal.vue";
import axios from "axios"; // Use your axios instance with interceptors
import eventBus from '@/utils/eventBus';

export default defineComponent({
  name: 'ProfileHeader',
  components: {
    DescriptionHeader,
    TipModal,
    SubscriptionModal,
  },
  props: {
    creator: {
      type: Object,
      required: true
    },
    bannerUrl: {
      type: String,
      default: ''
    },
    profileImageUrl: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isCreator: {
      type: Boolean,
      default: false
    },
    initialIsSubscribed: {
      type: Boolean,
      required: true
    },
  },
  setup() {
    const router = useRouter();
    return {router};
  },
  data() {
    return {
      isExpanded: false,
      showTipModal: false,
      showSubscriptionModal: false,
      isSubscribed: this.initialIsSubscribed,
      profileImage: null,
      bannerImage: null,
    };
  },
  computed: {
    bannerStyle() {
      return this.bannerUrl ? {} : {borderColor: "rgba(131, 95, 139, 1)"};
    },
    profileImageStyle() {
      return this.profileImageUrl
          ? {}
          : {
            fontFamily: "Rouge Script, cursive",
            backgroundColor: "#EDEADE",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
          };
    },
    descriptionText() {
      return this.description
          ? this.description
          : "Update this bio in the settings tab";
    },
    formattedDescription() {
      return this.descriptionText.replace(/\n/g, "<br>");
    },
    shortDescription() {
      const firstLine = this.descriptionText.split("\n")[0];
      return firstLine.replace(/\n/g, "<br>") + "...";
    },
    subscriptionPriceText() {
      return this.creator.subscription_price !== null && this.creator.subscription_price !== 0
          ? `$${this.creator.subscription_price} per month`
          : 'Free';
    },

      messageButtonTitle() {
        return this.isSubscribed
            ? 'Send a message'
            : 'Subscribe to send messages';
      },
    },
  methods: {
    toggleDescription() {
      this.isExpanded = !this.isExpanded;
    },
    async sendTip(tipData) {
      const accessToken = localStorage.getItem("access_token");
      try {
        const response = await axios.post(
            `/users/${this.creator.username}/tip`,
            {amount: tipData.amount},
            {headers: {Authorization: `Bearer ${accessToken}`}}
        );
        this.showTipModal = false;
        console.log("Tip sent successfully:", response.data);
      } catch (error) {
        console.error("Error sending tip:", error);
      }
    },
    handleMessageButtonClick() {
      if (this.isSubscribed) {
        this.startConversation();
      } else {
        this.toggleSubscriptionModal();
      }
    },
    async startConversation() {
      if (!this.isSubscribed) {
        console.log('User is not subscribed. Cannot start conversation.');
        return;
      }

      try {
        const response = await axios.get(`/users/${this.creator.username}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        });

        if (response.data) {
          this.router.push({
            path: '/messages',
            query: { user: response.data.username }
          });
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    },
    toggleSubscriptionModal() {
      this.showSubscriptionModal = !this.showSubscriptionModal;
    },
    async handleSubscribe() {
      try {
        await axios.post('/subscriptions',
            {creator_id: this.creator.id},
            {
              headers: {Authorization: `Bearer ${localStorage.getItem('access_token')}`}
            }
        );
        this.isSubscribed = true;
        this.showSubscriptionModal = false;
        eventBus.emit('subscription-changed', {creatorId: this.creator.id, isSubscribed: true});
      } catch (error) {
        console.error('Error subscribing:', error);
      }
    },
    async handleUnsubscribe() {
      try {
        await axios.delete('/subscriptions',
            {
              data: {creator_id: this.creator.id},
              headers: {Authorization: `Bearer ${localStorage.getItem('access_token')}`}
            }
        );
        this.isSubscribed = false;
        this.showSubscriptionModal = false;
        eventBus.emit('subscription-changed', {creatorId: this.creator.id, isSubscribed: false});
      } catch (error) {
        console.error('Error unsubscribing:', error);
      }
    },
    handlePaymentSuccess() {
      console.log("Payment successful");
    },
    async fetchProfileImage() {
      if (this.profileImageUrl) {
        const accessToken = localStorage.getItem('access_token');
        this.profileImage = `${this.profileImageUrl}?token=${accessToken}`;
      } else {
        this.profileImage = require("@/assets/images/picreator.webp");
      }
    },

    async fetchBannerImage() {
      if (this.bannerUrl) {
        const accessToken = localStorage.getItem('access_token');
        this.bannerImage = `${this.bannerUrl}?token=${accessToken}`;
      } else {
        this.bannerImage = require("@/assets/images/defaultbanner.webp");
      }
    },
    async checkSubscriptionStatus() {
      try {
        const response = await axios.get(`/subscriptions/check/${this.creator.username}`, {
          headers: {Authorization: `Bearer ${localStorage.getItem('access_token')}`}
        });
        this.isSubscribed = response.data.is_subscribed;
      } catch (error) {
        console.error('Error checking subscription status:', error);
      }
    },
  },
  mounted() {
    this.fetchProfileImage();
    this.fetchBannerImage();
    this.checkSubscriptionStatus();
    eventBus.on('subscription-changed', ({creatorId, isSubscribed}) => {
      if (creatorId === this.creator.id) {
        this.isSubscribed = isSubscribed;
      }
    });
  },
  beforeUnmount() {
    eventBus.off('subscription-changed');
  },
});
</script>



<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700&family=Rouge+Script&display=swap");

.profile-header-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 550px;
  height: 100%;
}

.profile-header {
  position: relative;
}

.banner-image-container img {
  width: 100%;
  border-start-end-radius: 40px;
  border-end-start-radius: 40px;
  top: -6px;
  object-fit: cover;
  max-height: 252px;
  box-shadow: 0 0.75rem 1rem -1rem rgba(0, 0, 0, 1),
  0 0rem 1.5rem 0 rgba(0, 0, 0, 0.1);
}

.profile-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  position: relative;
  margin-top: -45px;
  margin-left: 30px;
  right: 10px;
}

.profile-image-wrapper {
  min-width: 100px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid rgba(131, 95, 139, 1);
  margin-left: 10px;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.buttons-container {
  display: flex;
  gap: 20px;
  margin-top: 30px;
}

.round-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background-color: rgba(131, 95, 139, 0.7);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
  box-shadow: 0 0.75rem 1rem -1rem rgba(0, 0, 0, 1),
  0 0rem 1.5rem 0 rgba(0, 0, 0, 0.1);
}

.round-button:hover {
  background-color: rgba(131, 95, 139, 1);
}

.message-button img {
  width: 24px;
  height: 24px;
  filter: invert(1);
}

.tribute-button img {
  width: 50px;
  height: 50px;
  filter: invert(1);
}

.creator-name {
  font-family: "Rouge Script", cursive;
  font-weight: 200;
  font-size: 2em;
  margin-top: -20px;
  text-align: left;
  margin-left: 60px;
  color: var(--creator-name-color, white);
}

.description-container {
  padding-left: 60px;
  padding-right: 60px;
}

.description {
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.5;
  color: var(--description-color, white);
}

.toggle-button {
  background: none;
  border: none;
  color: #8e6495;
  cursor: pointer;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  text-decoration: none;
}

.toggle-button:hover {
  color: #a575b3;
}

.subscribebtn {
  height: 35px;
  width: 100%;
  margin: 20px auto 0;
  border-radius: 50px;
  background-color: rgba(97, 61, 105, 0.5);
  border: none;
  color: white;
  text-align: center;
  line-height: 35px;
  cursor: pointer;
  transition: all 0.3s;
  display: block;
}

.subscribebtn.subscribed {
  color: rgba(131, 95, 139, 1);
  background-color: transparent;
  border: 1px solid rgba(131, 95, 139, 1);
}

.subscribebtn:hover {
  opacity: 0.8;
}

@media screen and (max-width: 768px) {
  .profile-header-wrapper {
    width: 100%;
  }

  .profile-info {
    align-items: center;
  }

  .profile-image-wrapper {
    margin-bottom: 10px;
  }

  .buttons-container {
    margin-top: 20px;
  }

  .creator-name {
    right: 0;
  }

  .round-button {
    width: 60px;
    height: 60px;
  }
}
</style>